import { useCallback, useEffect, useState } from 'react'

import { getPreference, onPreferenceChange, Preferences, setPreference } from '@cash-web/preference-manager'
import logger from '@cash-web/shared/util-logger'

export function usePreference<Preference extends keyof Preferences>(preference: Preference) {
  const [state, setState] = useState<Preferences[Preference]>(() => getPreference(preference))

  const setGlobalPreference = useCallback(
    (value: Preferences[Preference]) => {
      logger.addBreadcrumb('Set preference', { preference })

      setPreference(preference, value)
    },
    [preference]
  )

  useEffect(() => onPreferenceChange(preference, setState), [preference])

  return [state, setGlobalPreference] as const
}
