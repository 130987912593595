import styled from '@emotion/styled'
import type { PropsWithChildren } from 'react'

import { ThemeProvider } from '@cash-web/shared-feature-theme-provider'
import { Footer, Header } from '@cash-web/support/ui-components'

import Link from './src/components/RegionalisedLink'

const GlobalContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-flow: column;
`

const BodyContainer = styled.div`
  background-color: ${props => props.theme.colors.behindBackground};
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
`

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider optOutOfDarkMode>
      <GlobalContainer>
        <Header Link={Link} linkProps={{ href: '/' }} />
        <BodyContainer>{children}</BodyContainer>
        <Footer Link={Link} linkProps={{ href: '/' }} />
      </GlobalContainer>
    </ThemeProvider>
  )
}

export default Layout
