import type { WebInteractClickParams } from '@squareup/customer-data-events-web'

export const eventActionType = {
  LINK_CLICK: 'LINK_CLICK',
  OPEN_MODAL: 'OPEN_MODAL',
  BLOCKER_ACTION: 'BLOCKER_ACTION',
}

export const getElementEventData = (
  element: EventTarget & HTMLAnchorElement,
  actionType = eventActionType.LINK_CLICK
): WebInteractClickParams => {
  return {
    pageName: element.text || element.href,
    componentName: element.tagName,
    componentType: actionType,
  }
}
