import React from 'react'

type FlagVariants = 'ENABLED' | 'DISABLED'

interface ArcadeComponentMigrationFeatureFlags {
  'cash-web-arcade-migration-button'?: FlagVariants
  'cash-web-arcade-migration-input'?: FlagVariants
}

export const ArcadeMigrationContext = React.createContext({
  featureFlags: {} as ArcadeComponentMigrationFeatureFlags,
})

export const ArcadeComponentMigrationProvider = ({
  featureFlags,
  children,
}: {
  featureFlags: ArcadeComponentMigrationFeatureFlags
  children: React.ReactNode
}) => {
  const values = React.useMemo(() => ({ featureFlags }), [featureFlags])

  return <ArcadeMigrationContext.Provider value={values}>{children}</ArcadeMigrationContext.Provider>
}

export const useArcadeMigrationFeatureFlag = (component: string) => {
  const { featureFlags } = React.useContext(ArcadeMigrationContext)
  const flag = `cash-web-arcade-migration-${component}` as keyof ArcadeComponentMigrationFeatureFlags

  return featureFlags?.[flag] === 'ENABLED'
}
