/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ApproveSponsorshipRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {ApproveSponsorshipResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const approveSponsorship = (request:ApproveSponsorshipRequest, options?: CashRequestOptions) => post<ApproveSponsorshipRequest, ApproveSponsorshipResponse>("/2.0/cash/approve-sponsorship", request, {...options, rejectOnError: true })