/** @typedef {import('@cash-web/shared-feature-region-provider').Region} Region */

/**
 * @template {Lowercase<Region>} K
 * @typedef {`${Lowercase<string>}-${Uppercase<K>}${string}`} Locale
 */

/**
 * @template {Lowercase<Region>} K
 * @typedef {object} RegionConfig
 * @property {K} region
 * @property {Locale<K>} defaultLocale
 * @property {ReadonlyArray<Locale<K>>} supportedLocales
 */

/** @type {{ [K in Lowercase<Region>]? : RegionConfig<K> }} */
const supportedRegions = {
  gb: { region: 'gb', defaultLocale: 'en-GB', supportedLocales: ['en-GB'] },
  us: { region: 'us', defaultLocale: 'en-US', supportedLocales: ['en-US'] },
}

const defaultRegion = /** @type {Lowercase<Region>} */ (
  /** @type {unknown} */ Object.keys(supportedRegions).find(region => region === 'us')
) // There's a test in NextJS.test.tsx to assert this is truthy

module.exports = { supportedRegions, defaultRegion }
