/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {DeleteGraduationJourneyEventRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {DeleteGraduationJourneyEventResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const deleteGraduationJourneyEvent = (request:DeleteGraduationJourneyEventRequest, options?: CashRequestOptions) => post<DeleteGraduationJourneyEventRequest, DeleteGraduationJourneyEventResponse>("/2.0/cash/delete-graduation-journey-event", request, {...options, rejectOnError: true })