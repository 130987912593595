import { useContext } from 'react'

import { DEFAULT_FLAGS, FeatureFlagContext } from '../FeatureFlagContext'
import { type FeatureFlags } from '../FeatureFlags'

export const useFeatureFlag = <T extends keyof FeatureFlags>(flag: T): Partial<FeatureFlags>[T] | undefined => {
  const flags = useContext(FeatureFlagContext)

  if (process.env['NODE_ENV'] === 'development' && flags === DEFAULT_FLAGS) {
    throw new Error('useFeatureFlag used outside of FeatureFlagProvider')
  }

  return flags?.[flag]
}
