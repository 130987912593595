/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {PublishTranslationCompleteEventRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {PublishTranslationCompleteEventResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const publishTranslationCompleteEvent = (request:PublishTranslationCompleteEventRequest, options?: CashRequestOptions) => post<PublishTranslationCompleteEventRequest, PublishTranslationCompleteEventResponse>("/2.0/cash/publish-translation-complete-event", request, {...options, rejectOnError: true })