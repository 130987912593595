import { useCallback } from 'react'

import { useRegion } from '@cash-web/shared-feature-region-provider'

import { logPageClick } from './eventstream2'

export type PageClickEventData = {
  action: string
  parent_identifier?: string
  identifier?: string
  type?: string
  title?: string
  text?: string
  target?: string
}

export function useLogPageClickEventstream(): (data: PageClickEventData) => void {
  const regionCode = useRegion()
  return useCallback(
    ({ action, parent_identifier, identifier, type, title, text, target }: PageClickEventData) => {
      logPageClick(
        action,
        {
          element_parent_identifier: parent_identifier,
          element_identifier: identifier,
          element_type: type,
          element_title: title,
          element_text: text,
          element_target: target,
        },
        regionCode
      )
    },
    [regionCode]
  )
}
