/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RetrieveAllTranslationsRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {RetrieveAllTranslationsResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const retrieveAllTranslations = (request:RetrieveAllTranslationsRequest, options?: CashRequestOptions) => post<RetrieveAllTranslationsRequest, RetrieveAllTranslationsResponse>("/2.0/cash/retrieve-all-translations", request, {...options, rejectOnError: true })