// Originally copied from https://github.com/squareup/sq-cash-frontend/blob/011d52e7a08b8f0231ebe048fa294b6604432b17/preact/src/utils/eventstream.js

import { Country } from '@cash-web/protos/squareup/common/countries.pb'
import { Orientation } from '@cash-web/protos/squareup/franklin/orientation.pb'
import { Role } from '@cash-web/protos/squareup/franklin/role.pb'
import { PaymentState } from '@cash-web/protos/squareup/franklin/ui/payment.pb'
import logger from '@cash-web/shared/util-logger'
import { redactTokensRecursive } from '@cash-web/shared/util-redaction'
import { canTrack } from '@cash-web/shared-util-can-track'
import { getDeviceInfo } from '@cash-web/shared-util-user-agent'

// Allowlist feature flags that we need to log to ES2.
// Amplitude has a limit of 1024 characters per string value. We've allowlisted flags we need
// so we can stay under this limit: https://help.amplitude.com/hc/en-us/articles/115002923888-Limits-How-much-data-can-you-send-to-Amplitude-
export const ALLOWLISTED_FLAGS_FOR_ES2 = [
  'cash-frontend-tax-upgrade',
  'cash-web-account-link-card-plasma-settings',
  'cash-web-account-sam',
  'cash-web-account-taxes-banner',
  'cash-web-account-taxes-entrypoint-in-sidebar-nav',
  'cash-web-account-taxes-returns-entrypoint-in-sidebar-documents-nav',
  'cash-web-account-activity-download',
  'cash-web-account-activity-filter-domain-type',
  'cash-web-account-filter-money-movement',
  'cash-web-account-show-account-number',
  'cash-web-account-show-money-tab-uk',
  'cash-web-account-show-p2p-for-uk',
  'cash-web-add-cash',
]

export function onlyKeepAllowlistedFeatureFlags(featureFlags: { [key in string]: unknown } | undefined) {
  const flagsCopy = Object.assign({}, featureFlags)

  Object.keys(flagsCopy).forEach(flag => {
    if (!ALLOWLISTED_FLAGS_FOR_ES2.includes(flag)) {
      delete flagsCopy[flag]
    }
  })

  return Object.keys(flagsCopy).length > 0 ? flagsCopy : undefined
}

// Gets the referrer as a Location object
function getReferrer(): HTMLAnchorElement | undefined {
  if (document.referrer) {
    const parser = document.createElement('a')
    parser.href = document.referrer
    return parser
  }
  return undefined
}

type EventParams = {
  cash_web_event_description?: string
  cash_web_event_category?: string
  cash_web_event_detail?: string
  cash_web_event_amount?: number
}

type PageClickEventParams = {
  element_parent_identifier?: string
  element_identifier?: string
  element_type?: string
  element_title?: string
  element_text?: string
  element_target?: string
}

// These params match what is in sq-cash-frontend:
// https://github.com/squareup/sq-cash-frontend/blob/500c0c89f30aa1f1f34e889f63bc02a0d9c42d95/app/services/analytics.js#L133-L140
export type MoneyMovementParams = {
  cash_web_money_movement_type: 'PAYMENT' | 'TRANSFER'
  cash_web_money_movement_amount_money: number
  cash_web_money_movement_mechanism: 'payment-flow' | 'create-payment' | 'profile-page'
  cash_web_money_movement_orientation?: Orientation
  cash_web_money_movement_role?: Role
  cash_web_money_movement_state?: PaymentState
}

export type Region = `${Country}`

export async function logWebEvent(actionName: string, params: EventParams = {}, region?: Region, hostName?: string) {
  await logEvent({
    catalogName: 'cash_web_event',
    params: {
      cash_web_event_action: actionName,
      cash_web_event_description: params.cash_web_event_description,
      cash_web_event_category: params.cash_web_event_category,
      cash_web_event_detail: params.cash_web_event_detail,
      cash_web_event_amount: params.cash_web_event_amount,
    },
    region,
    hostName,
  })
}

export async function logPageView(page: string, region?: Region) {
  await logEvent({
    catalogName: 'page_view',
    params: { webpage_title: page, locale_language: navigator.language },
    region,
  })
}

export async function logMoneyMovement(params: MoneyMovementParams, region?: Region) {
  await logEvent({
    catalogName: 'cash_web_money_movement',
    params,
    region,
  })
}

export async function logPageClick(actionName: string, params: PageClickEventParams, region?: Region) {
  await logEvent({
    catalogName: 'page_click',
    params: {
      page_click_description: '', // Eventstream breaks up page_click events by description. We want them all grouped together.
      page_click_action: actionName,
      element_parent_identifier: params.element_parent_identifier,
      element_identifier: params.element_identifier,
      element_type: params.element_type,
      element_title: params.element_title,
      element_text: params.element_text,
      element_target: params.element_target,
    },
    region,
  })
}

interface LogEventParams {
  catalogName: string
  params: Record<string, unknown>
  region?: Region
  hostName?: string
  /**
   * prevents data loss because sendBeacon guarantees to initiate requests before the page is
   * unloaded and to run them to completion
   */
  sendBeacon?: boolean
}

async function logEvent({ catalogName, params, region, hostName }: LogEventParams) {
  if (canTrack(region)) {
    const data = normalizeData(catalogName, params)
    // Note: the app not necessarily sitting in the same domain, hostname will need to be configurable
    const endpoint = `${hostName ?? ''}/event/eventstream2`

    try {
      const headers = { type: 'application/json; charset=utf-8' }
      const blob = new Blob([JSON.stringify(data)], headers)
      navigator?.sendBeacon(endpoint, blob)
    } catch (e) {
      try {
        // Defer import until client side render
        const { post } = await import('@cash-web/shared-util-fetch')
        await post(endpoint, data)
      } catch (e) {
        logger.error(new Error(`Eventstream error: ${e}`))
      }
    }
  }
}

function compactObject(object: Record<string, unknown>) {
  let key, value
  const clone: Record<string, unknown> = {}
  for (key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      value = object[key]
      if (value !== null && value !== undefined) {
        clone[key] = value
      }
    }
  }
  return clone
}

function normalizeData(catalogName: string, params: Record<string, unknown>) {
  const { browser, device, os } = getDeviceInfo()

  params = params || {}
  const location = window.location
  const referrer = getReferrer()

  params = Object.assign({}, params, {
    catalog_name: catalogName,

    // Browser
    browser_height: window.innerHeight,
    browser_width: window.innerWidth,
    browser_major_version: browser.major,
    browser_name: browser.name,
    browser_version: browser.version,

    // Device
    device_form_factor: device.type,
    device_manufacturer: device.vendor,
    device_model: device.model,
    device_density_dpi: window.devicePixelRatio || 1,
    device_screen_height: window.screen.height,
    device_screen_width: window.screen.width,
    // To differentiate from Ember events, take over a device parameter we don't use.
    device_build_device: 'react',

    // OS
    os_name: os.name,
    os_version: os.version,

    // Web Page
    webpage_base_url: location.hostname + location.pathname,
    webpage_full_url: location.href,
    webpage_path: location.pathname,
    webpage_referrer: referrer?.href,
    webpage_referrer_host: referrer?.hostname,
    webpage_referrer_path: referrer?.pathname,
    webpage_title: null, // Purposefully left blank to avoid logging PII.

    // Experiments
    webpage_dashboard_version: JSON.stringify(onlyKeepAllowlistedFeatureFlags(window.featureFlagsJson)),
  })

  redactTokensRecursive(params)
  const compactedObject = compactObject(params)

  // Amplitude has a limit of 1024 characters for any string property: https://help.amplitude.com/hc/en-us/articles/115002923888-Limits-How-much-data-can-you-send-to-Amplitude-
  const featureFlags = compactedObject['webpage_dashboard_version']
  if (featureFlags && (featureFlags as string).length > 1024) {
    logger.warn(new Error('Eventstream2: webpage_dashboard_version is too long, will be cut off in Amplitude'))
  }

  return compactedObject
}
