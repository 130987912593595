import { useCallback } from 'react'

import { useRegion } from '@cash-web/shared-feature-region-provider'

import { logMoneyMovement, MoneyMovementParams } from './eventstream2'

type MoneyMovementData = {
  type: MoneyMovementParams['cash_web_money_movement_type']
  amount: MoneyMovementParams['cash_web_money_movement_amount_money']
  mechanism: MoneyMovementParams['cash_web_money_movement_mechanism']
  orientation?: MoneyMovementParams['cash_web_money_movement_orientation']
  role?: MoneyMovementParams['cash_web_money_movement_role']
  state?: MoneyMovementParams['cash_web_money_movement_state']
}

export function useLogMoneyMovementEventstream() {
  const regionCode = useRegion()

  return useCallback(
    async ({ type, amount, orientation, role, mechanism, state }: MoneyMovementData) => {
      await logMoneyMovement(
        {
          cash_web_money_movement_type: type,
          cash_web_money_movement_amount_money: amount,
          cash_web_money_movement_orientation: orientation,
          cash_web_money_movement_role: role,
          cash_web_money_movement_mechanism: mechanism,
          cash_web_money_movement_state: state,
        },
        regionCode
      )
    },
    [regionCode]
  )
}
