import * as React from 'react'

import { css, Global, useTheme } from '@emotion/react'

import { Colors } from '../theme/colors'

interface Props {
  bodyBackground?: Colors
  fonts?: boolean
}

// Allow to maintain backwards combatibility for consumers still referencing fonts via "@cash-design-system/react".
// The lint rule itself requires consumers of this lib within cash-web to set `fonts={false}` to be compliant.
// eslint-disable-next-line @nx/workspace/no-hardcoded-cdn-urls
const LEGACY_FONT_CDN_BASE = 'https://cash-f.squarecdn.com'

const GlobalStyles: React.FC<Props> = ({ bodyBackground = 'background', fonts = true }) => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        ${fonts &&
        // NOTE: This is a legacy option enabled to maintain backwards compatibility. New fonts or changes to fonts
        // are strongly discouraged here and should be added to "@cash-web/fonts".
        css`
          @font-face {
            font-family: cash-market-rounded;
            src: url('${LEGACY_FONT_CDN_BASE}/static/fonts/cash-market/v2/CashMarket-BoldRounded.woff2') format('woff2');
            font-weight: ${theme.weights.bold};
            font-style: normal;
          }

          @font-face {
            font-family: cash-market-rounded;
            src: url('${LEGACY_FONT_CDN_BASE}/static/fonts/cash-market/v2/CashMarket-MediumRounded.woff2')
              format('woff2');
            font-weight: ${theme.weights.medium};
            font-style: normal;
          }

          @font-face {
            font-family: cash-market-rounded;
            src: url('${LEGACY_FONT_CDN_BASE}/static/fonts/cash-market/v2/CashMarket-RegularRounded.woff2')
              format('woff2');
            font-weight: ${theme.weights.regular};
            font-style: normal;
          }

          @font-face {
            font-family: agrandir-grand;
            src: url('${LEGACY_FONT_CDN_BASE}/static/fonts/agrandir/Agrandir-GrandHeavy.woff2') format('woff2');
            font-weight: ${theme.weights.bold};
            font-style: normal;
          }
        `}

        * {
          font-family: cash-market-rounded, 'Helvetica Neue', helvetica, sans-serif;
        }

        html {
          box-sizing: border-box;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        body {
          background: ${theme.colors[bodyBackground]};
          color: ${theme.colors.body};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          ${theme.typography.body};
          /* Resetting line-height to browser default to overwrite the line-height set in theme.typography.body
          * This is to ensure that the line-height is not inherited by non-text elements.
          */
          line-height: unset;
        }

        body,
        div,
        dl,
        dt,
        dd,
        ul,
        ol,
        li,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        pre,
        form,
        fieldset,
        input,
        textarea,
        p,
        blockquote,
        th,
        td,
        hr {
          margin: 0;
          padding: 0;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        fieldset,
        img {
          border: 0;
        }

        address,
        caption,
        cite,
        code,
        dfn,
        em,
        th,
        var {
          font-style: normal;
          font-weight: normal;
        }

        strong,
        b {
          font-style: normal;
          font-weight: ${theme.weights.medium};
        }

        ol,
        ul {
          list-style: none;
        }

        caption,
        th {
          text-align: left;
        }
      `}
    />
  )
}

export default GlobalStyles
