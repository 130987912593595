import { useMemo } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import React, { createContext } from 'react'

import type { PropsWithChildren } from 'react'

import { uuidv4 } from '@cash-web/shared-util-formatting'

interface AnalyticsContextProps {
  viewToken?: string
}

const AnalyticsContext = createContext<AnalyticsContextProps>({
  viewToken: undefined,
})

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  // Only generate a UUID on client-side
  const [viewToken] = useState(typeof window !== 'undefined' ? uuidv4() : undefined)

  const contextValue = useMemo(() => ({ viewToken }), [viewToken])

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>
}

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)

  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider')
  }

  return context
}
