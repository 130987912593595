import { forwardRef } from 'react'

import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { PropsWithChildren } from 'react'
import type { UrlObject } from 'url'

import { useCountryFromRegionProvider } from '@cash-web/shared-feature-region-provider'

import { fallbackLng as defaultLocale } from '../../../next-i18next.config'
import { defaultRegion } from '../../config/regions'

interface RegionalisedLinkProps
  extends Omit<LinkProps, 'href'>,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
  href: Exclude<LinkProps['href'], UrlObject> // TODO support UrlObject
}

const RegionalisedLink = forwardRef<HTMLAnchorElement, PropsWithChildren<RegionalisedLinkProps>>(
  ({ children, ...props }, ref) => {
    const { locale } = useRouter()
    const region = useCountryFromRegionProvider()?.toLowerCase()

    const regionToUse = region == defaultRegion ? '' : `/${region}`

    const regex = new RegExp(`^/${defaultRegion}/|$`)

    return (
      <Link
        {...props}
        href={`${regionToUse}${props.href.replace(regex, '')}`}
        locale={locale == defaultLocale ? false : undefined}
        ref={ref}
      >
        {children}
      </Link>
    )
  }
)

RegionalisedLink.displayName = 'RegionalisedLink'

export default RegionalisedLink
