/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HandleGraduationJourneyEventRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {HandleGraduationJourneyEventResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const handlerGraduationJourneyEvent = (request:HandleGraduationJourneyEventRequest, options?: CashRequestOptions) => post<HandleGraduationJourneyEventRequest, HandleGraduationJourneyEventResponse>("/2.0/cash/handler-graduation-journey-event", request, {...options, rejectOnError: true })