/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CompleteProductRequestRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {CompleteProductRequestResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const completeProductRequest = (request:CompleteProductRequestRequest, options?: CashRequestOptions) => post<CompleteProductRequestRequest, CompleteProductRequestResponse>("/2.0/cash/complete-product-request", request, {...options, rejectOnError: true })