/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetFamilyPendingRequestsRequest} from '@cash-web/protos/squareup/cash/aegis/api/GetFamilyPendingRequestsRequest.pb'
import {GetFamilyPendingRequestsResponse} from '@cash-web/protos/squareup/cash/aegis/api/GetFamilyPendingRequestsResponse.pb'


export const getFamilyPendingRequests = (request:GetFamilyPendingRequestsRequest, options?: CashRequestOptions) => post<GetFamilyPendingRequestsRequest, GetFamilyPendingRequestsResponse>("/2.0/cash/get-family-pending-requests", request, {...options, rejectOnError: true })