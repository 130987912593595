/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateSponsorshipLimitRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {UpdateSponsorshipLimitResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const updateSponsorshipLimit = (request:UpdateSponsorshipLimitRequest, options?: CashRequestOptions) => post<UpdateSponsorshipLimitRequest, UpdateSponsorshipLimitResponse>("/2.0/cash/update-sponsorship-limit", request, {...options, rejectOnError: true })