/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ResendRequestSponsorshipNotificationRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {ResendRequestSponsorshipNotificationResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const resendRequestSponsorshipNotification = (request:ResendRequestSponsorshipNotificationRequest, options?: CashRequestOptions) => post<ResendRequestSponsorshipNotificationRequest, ResendRequestSponsorshipNotificationResponse>("/2.0/cash/resend-request-sponsorship-notification", request, {...options, rejectOnError: true })