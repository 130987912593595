/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateEnablementDateRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {UpdateEnablementDateResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const updateEnablementDate = (request:UpdateEnablementDateRequest, options?: CashRequestOptions) => post<UpdateEnablementDateRequest, UpdateEnablementDateResponse>("/2.0/cash/update-enablement-date", request, {...options, rejectOnError: true })