import { typography as typographyDefaults } from '@arcade/web'

// =========== UTILS ===========
export const pxToRem = (px: number) => `${px / 16}rem`
export const remToPx = (rem: number) => `${rem * parseFloat(getComputedStyle(document.documentElement).fontSize)}px`
// ==============================

// { heroNumerics: {...}, keypadTotal: {...} }
type ArcadeTypography = keyof typeof typographyDefaults

// { fontSize: '...', lineHeight: '...', fontWeight: ... }
type FontStyles = string

// { [heroNumerics]: { fontSize: '...', lineHeight: '...', fontWeight: ... } }
type SetType = { [key in ArcadeTypography]: FontStyles }

/**
 * Returns the font styles for the given typography key.
 * @param key - The typography key.
 * @returns The font styles.
 * @example
 * const styles = getFontStyles('heroNumerics')
 * // => 'font-size: 2.5rem; font-weight: 700; line-height: 1.2;'
 */
const getFontStyles = (key: ArcadeTypography): FontStyles => {
  const arcadeStyle = typographyDefaults[key as ArcadeTypography]
  const { fontSize, lineHeight, fontWeight, letterSpacing } = arcadeStyle

  return `
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};
      letter-spacing: ${letterSpacing};
    `
}

const typography: SetType = Object.keys(typographyDefaults).reduce((set, key) => {
  set[key as ArcadeTypography] = getFontStyles(key as ArcadeTypography)
  return set
}, {} as SetType)

type Typography = keyof typeof typography
export type ThemeTypography = Record<Typography, FontStyles>

export default typography
