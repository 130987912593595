/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SeedDevelopmentDataRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {SeedDevelopmentDataResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const seedDevelopmentData = (request:SeedDevelopmentDataRequest, options?: CashRequestOptions) => post<SeedDevelopmentDataRequest, SeedDevelopmentDataResponse>("/2.0/cash/seed-development-data", request, {...options, rejectOnError: true })