import type { Region } from '@cash-web/shared-feature-region-provider'

const DEFAULT_LEGAL_URL = 'https://cash.app/legal/tos'
const STATUS_PAGE_URL = 'https://status.cash.app'
const CASH_APP_URL = 'https://cash.app'
const CONTACT_URL = '/contact'
interface FooterLink {
  [key: string]: string
}

const DEFAULT_LINKS: FooterLink = {
  cashApp: CASH_APP_URL,
  contact: CONTACT_URL,
  status: STATUS_PAGE_URL,
  legal: DEFAULT_LEGAL_URL,
}

/**
 * Country/Region specific footer links, everything else should use {@link DEFAULT_LINKS}
 */
// if you're adding more links, make sure you add equivalent titles in /locales/<lang>/translations.js
const REGION_FOOTER_LINKS: { [region in Region]?: FooterLink } = {
  US: {
    cashApp: CASH_APP_URL,
    contact: CONTACT_URL,
    status: STATUS_PAGE_URL,
    security: '/security',
    legal: 'https://cash.app/legal/tos',
  },
  GB: {
    cashApp: CASH_APP_URL,
    contact: CONTACT_URL,
    status: STATUS_PAGE_URL,
    legal: 'https://squareup.com/gb/legal/cash-ua',
  },
  IE: {
    cashApp: CASH_APP_URL,
    contact: CONTACT_URL,
    status: STATUS_PAGE_URL,
    legal: 'https://cash.app/legal/ie/en-ie/tos',
  },
}

/**
 * Returns the footer links for the region provided
 * @param region the Uppercase 2 ISO-3166-1 Country Code {@link https://www.iso.org/iso-3166-country-codes.html}
 * @returns an object containing the links that should be displayed in the footer
 */
export const getFooterLinks = (region?: Region): FooterLink => (region && REGION_FOOTER_LINKS[region]) ?? DEFAULT_LINKS
