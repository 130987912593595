import React, { ReactNode } from 'react'

import { ThemeProvider, useTheme } from '@emotion/react'
import whatInput from 'what-input'

import { ArcadeIconProvider } from '@arcade/web/icons/react'

import breakpoints from '../theme/breakpoints'
import { addOpacity, Colors, getColorsByThemeVariant, ThemeVariant } from '../theme/colors'
import getDropShadows from '../theme/dropShadows'
import maxWidths from '../theme/maxWidths'
import motion from '../theme/motion'
import selectors from '../theme/selectors'
import spacing from '../theme/spacing'
import typography, { pxToRem, remToPx } from '../theme/typography'
import weights from '../theme/weights'
import { ArcadeComponentMigrationProvider } from './ArcadeComponentMigration/ArcadeComponentMigrationProvider'
import GlobalStyles from './GlobalStyles'

const ESC_CODE = 27
whatInput.ignoreKeys([ESC_CODE])

interface Props {
  themeVariant?: ThemeVariant
  darkMode: boolean
  children: React.ReactNode
  bodyBackground?: Colors
  /**
   * Loads fonts as part of the design system, defaults to `true`. The intent is to migrate apps away from
   * this and onto `@cash-web/fonts` where there are build-time allowances to enable font preloading and CDN templatization.
   */
  fonts?: boolean
  /**
   * Arcade component migration feature flags to be passed down to the ArcadeMigrationProvider
   */
  featureFlags?: Record<string, string>
}

const ThemedArcadeIconProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  return (
    <ArcadeIconProvider
      variants={{
        prominent: theme.colors.iconProminent,
        standard: theme.colors.icon,
        subtle: theme.colors.iconSubtle,
        'extra-subtle': theme.colors.iconExtraSubtle,
        inverse: theme.colors.iconInverse,
        disabled: theme.colors.iconDisabled,
        danger: theme.colors.iconDanger,
        warning: theme.colors.iconWarning,
        success: theme.colors.iconSuccess,
        info: theme.colors.iconInfo,
      }}
    >
      {children}
    </ArcadeIconProvider>
  )
}

const CashDesignSystemProvider: React.FC<Props> = ({
  children,
  darkMode,
  bodyBackground = 'background',
  themeVariant = 'default',
  fonts,
  featureFlags = {},
}) => {
  const colors = getColorsByThemeVariant({ themeVariant, darkMode })
  const dropShadows = getDropShadows(darkMode)

  // Note: The `what-import` module has a side effect, must include this in imported code to ensure its bundled.
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  whatInput

  return (
    <ThemeProvider
      theme={{
        darkMode,
        colors,
        addOpacity,
        maxWidths,
        motion,
        typography,
        weights,
        dropShadows,
        breakpoints,
        pxToRem,
        remToPx,
        selectors,
        spacing,
      }}
    >
      <GlobalStyles bodyBackground={bodyBackground} fonts={fonts} />
      <ThemedArcadeIconProvider>
        <ArcadeComponentMigrationProvider featureFlags={featureFlags}>{children}</ArcadeComponentMigrationProvider>
      </ThemedArcadeIconProvider>
    </ThemeProvider>
  )
}

export default CashDesignSystemProvider
