/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetProductRequestsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetProductRequestsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getProductRequests = (request:GetProductRequestsRequest, options?: CashRequestOptions) => post<GetProductRequestsRequest, GetProductRequestsResponse>("/2.0/cash/get-product-requests", request, {...options, rejectOnError: true })