import { Calculations } from '@cash-web/fetch-opaque-headers'

import { CashRequestMiddleware } from './types'

const toIsoString = (date: Date) => {
  const tzo = -date.getTimezoneOffset()

  const dif = tzo >= 0 ? '+' : '-'
  const pad = (num: number) => (num < 10 ? '0' : '') + num

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

const getDateWithTimezone = () => {
  const timeNow = new Date()
  const isoString = toIsoString(timeNow)
  const tz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || ''
  return isoString + ';;' + tz
}

export const signatureRequest: CashRequestMiddleware = async (request, context) => {
  const headers = request.headers as Record<string, string>
  headers['X-JS-ID'] = 'no'
  headers['Time-Zone'] = getDateWithTimezone()

  Calculations.setContent(headers, context?.path ?? '', request.body?.toString())
  return request
}
