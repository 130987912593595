/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetCustomerForGraduationJourneyEventTokenRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetCustomerForGraduationJourneyEventTokenResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getCustomerForGraduationJourneyEventToken = (request:GetCustomerForGraduationJourneyEventTokenRequest, options?: CashRequestOptions) => post<GetCustomerForGraduationJourneyEventTokenRequest, GetCustomerForGraduationJourneyEventTokenResponse>("/2.0/cash/get-customer-for-graduation-journey-event-token", request, {...options, rejectOnError: true })