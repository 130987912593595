/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ES2TrackEventRequest} from '@cash-web/protos/squareup/cashfrontend/app.pb'
import {ES2TrackEventResponse} from '@cash-web/protos/squareup/cashfrontend/app.pb'


export const eS2TrackEvent = (request:ES2TrackEventRequest, options?: CashRequestOptions) => post<ES2TrackEventRequest, ES2TrackEventResponse>("/2.0/cash/e-s2-track-event", request, {...options, rejectOnError: true })