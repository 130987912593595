/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RetrieveTranslationsRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {RetrieveTranslationsResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const retrieveTranslations = (request:RetrieveTranslationsRequest, options?: CashRequestOptions) => post<RetrieveTranslationsRequest, RetrieveTranslationsResponse>("/2.0/cash/retrieve-translations", request, {...options, rejectOnError: true })