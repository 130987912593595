/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {MakeTriggeredActionGraduationJourneyEventRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {MakeTriggeredActionGraduationJourneyEventResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const makeTriggeredActionGraduationJourneyEvent = (request:MakeTriggeredActionGraduationJourneyEventRequest, options?: CashRequestOptions) => post<MakeTriggeredActionGraduationJourneyEventRequest, MakeTriggeredActionGraduationJourneyEventResponse>("/2.0/cash/make-triggered-action-graduation-journey-event", request, {...options, rejectOnError: true })