import styled from '@emotion/styled'
import type { AnchorHTMLAttributes, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { breakpoints, Content, spacing } from '@cash-design-system/react'
import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import { useRegion } from '@cash-web/shared-feature-region-provider'
import { getElementEventData } from '@cash-web/support/utils'
import { WebInteractClick } from '@squareup/customer-data-events-web'

import CashEye from '../../assets/cash-eye.svg.js'
import type { LinkAdapterProps } from '../../types/types'
import SupportLogo from '../SupportLogo'
import { getFooterLinks } from './getFooterLinks'

const StyledFooter = styled.footer`
  box-shadow: ${props => props.theme.dropShadows.bottomBar};
  z-index: 100;
`
const FooterContent = styled(Content)`
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacing.xl};
  padding-bottom: ${spacing.xl};

  ${breakpoints.tablet} {
    flex-flow: row wrap;
  }
`
const StyledNav = styled.nav`
  --gapSize: ${spacing.xxl};

  position: relative;
  display: inline-flex;
  align-items: center;
  flex-flow: column nowrap;
  gap: ${spacing.m};
  width: 100%;
  padding-top: var(--gapSize);

  &::before {
    content: '';
    position: absolute;
    border-top: 1px solid ${props => props.theme.colors.outline};
    width: 100%;
    height: 1px;
    top: calc(var(--gapSize) * 0.5);
  }

  ${breakpoints.tablet} {
    flex-flow: row wrap;
    gap: ${spacing.l};
    width: auto;
    padding-top: 0;

    &::before {
      display: none;
    }
  }
`

const StyledAnchor = styled.a`
  text-decoration: none;
  display: inline-flex;
  ${props => props.theme.typography.helpText};
  color: ${props => props.theme.colors.label};
`

const Anchor = ({ children, ...props }: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'>) => {
  const logCdfEvent = useLogCashWebCdfEvent()
  const trackEvent = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    const element = e.currentTarget
    logCdfEvent(WebInteractClick(getElementEventData(element)))
  }

  return (
    <StyledAnchor onClick={trackEvent} target="_blank" rel="noreferrer" {...props}>
      {children}
    </StyledAnchor>
  )
}

const Footer = <T,>(props: LinkAdapterProps<T>) => {
  const { t } = useTranslation()
  const regionCode = useRegion()
  const regionLinks = getFooterLinks(regionCode)

  const getFooterText = (key: string) => {
    switch (key) {
      case 'legal':
        return t('Legal', { context: 'legal footer link title' })
      case 'security':
        return t('Security', { context: 'security footer link title' })
      case 'careers':
        return t('Careers', { context: 'careers footer link title' })
      case 'privacy':
        return t('Privacy', { context: 'privacy footer link title' })
      case 'bitcoin':
        return t('Bitcoin', { context: 'bitcoin footer link title' })
      case 'status':
        return t('Status', { context: 'status footer link title' })
      case 'contact':
        return t('Contact', { context: 'contact footer link title' })
      case 'developers':
        return t('Developers', { context: 'developers footer link title' })
      case 'cashApp':
      default:
        return t('Cash App', { context: 'cashApp footer link title' })
    }
  }

  return (
    <StyledFooter>
      <FooterContent>
        <SupportLogo Link={props.Link} linkProps={props.linkProps} />
        <StyledNav>
          {Object.keys(regionLinks).map(key => (
            <Anchor key={key} href={regionLinks[key]}>
              {getFooterText(key)}
            </Anchor>
          ))}
          <CashEye />
        </StyledNav>
      </FooterContent>
    </StyledFooter>
  )
}

export default Footer
