import { useEffect, useState } from 'react'

import logger from '@cash-web/shared/util-logger'

import { FeatureFlagContext } from './FeatureFlagContext'
import { FeatureFlags } from './FeatureFlags'

export const FeatureFlagProvider = ({
  override,
  children,
}: {
  override?: Partial<FeatureFlags>
  children: React.ReactNode
}) => {
  const [featureFlags] = useState<FeatureFlags>(
    (typeof window === 'undefined' ? undefined : window.featureFlagsJson || {}) as FeatureFlags
  )

  useEffect(() => {
    logger.clearFeatureFlags()
    logger.addFeatureFlags(Object.entries(featureFlags).map(([key, value]) => ({ flag: key, value })))
  }, [featureFlags])

  return <FeatureFlagContext.Provider value={{ ...featureFlags, ...override }}>{children}</FeatureFlagContext.Provider>
}
