import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { ReactElement } from 'react'

import { PushIcon } from '@arcade/web/icons/react'
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 16px 24px 24px;
  height: 100%;
  background-color: ${props => props.theme.colors.elevatedBackground};
  color: ${props => props.theme.colors.label};
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-left: ${props => props.theme.pxToRem(17)};
`
const Title = styled.span`
  ${props => props.theme.typography.label}
`

const Subtitle = styled.span`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.label};

  // restrict our card items to 2 lines and ellipse
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const IconStyles = (props: { theme: Theme }) => css`
  width: ${props.theme.pxToRem(20)};
  height: ${props.theme.pxToRem(20)};
  align-self: flex-start;
  margin-top: ${props.theme.pxToRem(2)};
`
const IconContainer = styled.div`
  display: flex;
  > svg {
    ${IconStyles}
  }
`

const StyledChevronIcon = styled.div`
  svg {
    color: ${props => props.theme.colors.secondaryButtonHover};
  }
`

export interface CardItemProps {
  title: string
  subtitle?: string
  icon: ReactElement
  isClickable?: boolean
}

export default function CardItem({ title, subtitle, icon, isClickable }: CardItemProps) {
  return (
    <Card>
      <IconContainer>{icon}</IconContainer>
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Content>

      {isClickable && (
        <StyledChevronIcon data-testid="chevron-right">
          <PushIcon />
        </StyledChevronIcon>
      )}
    </Card>
  )
}
