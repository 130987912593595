/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SubmitTranslationsRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {SubmitTranslationsResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const submitTranslations = (request:SubmitTranslationsRequest, options?: CashRequestOptions) => post<SubmitTranslationsRequest, SubmitTranslationsResponse>("/2.0/cash/submit-translations", request, {...options, rejectOnError: true })