/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ActivateGraduationJourneyRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {ActivateGraduationJourneyResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const activateGraduationJourney = (request:ActivateGraduationJourneyRequest, options?: CashRequestOptions) => post<ActivateGraduationJourneyRequest, ActivateGraduationJourneyResponse>("/2.0/cash/activate-graduation-journey", request, {...options, rejectOnError: true })