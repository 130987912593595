/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetTranslationsStatusesRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {GetTranslationsStatusesResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const getTranslationsStatuses = (request:GetTranslationsStatusesRequest, options?: CashRequestOptions) => post<GetTranslationsStatusesRequest, GetTranslationsStatusesResponse>("/2.0/cash/get-translations-statuses", request, {...options, rejectOnError: true })