import React from 'react'

import { useTranslation } from 'react-i18next'

export const CashLogo = () => {
  const { t } = useTranslation()

  return (
    <svg
      data-testid="cash-support-logo"
      width="28"
      height="28"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="cashLogoTitle">{t('Cash App Support Logo', { context: 'Cash App Support logo title' })}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4762 -2.5098e-05C42.098 -2.5098e-05 44.9081 -2.5098e-05 47.9344 0.956641C51.2384 2.15909 53.8409 4.76153 55.0433 8.06553C56 11.0911 56 13.9028 56 19.5245V36.4754C56 42.098 56 44.9081 55.0433 47.9344C53.8409 51.2384 51.2384 53.8409 47.9344 55.0433C44.9081 56 42.098 56 36.4762 56H19.5246C13.902 56 11.0911 56 8.06556 55.0433C4.76156 53.8409 2.15911 51.2384 0.956667 47.9344C0 44.9081 0 42.098 0 36.4754L0 19.5245C0 13.9028 0 11.0911 0.956667 8.06553C2.15911 4.76153 4.76156 2.15909 8.06556 0.956641C11.0911 -2.5098e-05 13.902 -2.5098e-05 19.5246 -2.5098e-05L36.4762 -2.5098e-05Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1509 18.9187C31.8583 18.9187 34.4532 20.015 36.1488 21.5199C36.5776 21.9006 37.2196 21.8983 37.6219 21.4904L39.6393 19.4444C40.065 19.0125 40.0494 18.3055 39.5897 17.9108C38.236 16.7471 36.4187 15.6027 34.1819 14.87L34.8145 11.8572C34.9518 11.2005 34.4517 10.5833 33.7803 10.5833H29.882C29.3835 10.5833 28.9524 10.9322 28.8485 11.4199L28.2786 14.0994C23.0964 14.3583 18.7043 16.944 18.7043 22.2495C18.7043 26.8424 22.3382 28.811 26.1737 30.1732C29.8076 31.5354 31.7249 32.0409 31.7249 33.9583C31.7249 35.9268 29.8076 37.0874 26.9808 37.0874C24.4036 37.0874 21.704 36.2369 19.6091 34.1715C19.1982 33.766 18.5407 33.7629 18.1298 34.1684L15.962 36.3067C15.5379 36.7246 15.5418 37.4115 15.969 37.8255C17.6592 39.4661 19.799 40.6539 22.2413 41.3191L21.6466 44.1102C21.5078 44.7646 22.0032 45.3817 22.6731 45.3872L26.5776 45.4166C27.08 45.4205 27.5165 45.0693 27.6188 44.577L28.1825 41.8936C34.3873 41.5106 38.1848 38.138 38.1848 33.2016C38.1848 28.6598 34.4005 26.7416 29.8076 25.1771C27.1831 24.2172 24.9114 23.5621 24.9114 21.5943C24.9114 19.677 27.0319 18.9187 29.1509 18.9187Z"
        fill="white"
      />
    </svg>
  )
}
