import { useEffect, useState } from 'react'

function extractValue<T>(value: T | (() => T)) {
  return value instanceof Function ? value() : value
}

/**
 * Sourced from https://usehooks.com/useLocalStorage/
 */
function useStorage<T>(
  type: 'localStorage' | 'sessionStorage',
  key: string,
  initialValue: T | (() => T),
  onError?: (error: unknown) => void
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const defaultValue = extractValue(initialValue)

    if (typeof window === 'undefined') {
      return defaultValue
    }

    try {
      // Get from local storage by key
      const item = window[type].getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : defaultValue
    } catch (e) {
      onError?.(e)
      return defaultValue
    }
  })

  useEffect(() => {
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T) => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window[type].setItem(key, JSON.stringify(valueToStore))
    }

    try {
      setValue(storedValue)
    } catch (e) {
      onError?.(e)
    }
  }, [storedValue, key, type, onError])

  return [storedValue, setStoredValue] as const
}

export function useLocalStorage<T>(key: string, initialValue: T | (() => T), onError?: (error: unknown) => void) {
  return useStorage('localStorage', key, initialValue, onError)
}

export function useSessionStorage<T>(key: string, initialValue: T | (() => T), onError?: (error: unknown) => void) {
  return useStorage('sessionStorage', key, initialValue, onError)
}
