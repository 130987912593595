/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSponsorshipPeriodsGivenSponsorRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetSponsorshipPeriodsGivenSponsorResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getSponsorshipPeriodsGivenSponsor = (request:GetSponsorshipPeriodsGivenSponsorRequest, options?: CashRequestOptions) => post<GetSponsorshipPeriodsGivenSponsorRequest, GetSponsorshipPeriodsGivenSponsorResponse>("/2.0/cash/get-sponsorship-periods-given-sponsor", request, {...options, rejectOnError: true })