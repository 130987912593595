const path = require('path')

const Bugsnag = require('@bugsnag/js').default
const { PHASE_PRODUCTION_BUILD } = require('next/constants')

const locales = /** @type {const} */ (['default', 'en-US', 'en-GB'])

/**
 * @type typeof locales[number]
 * This is the in actual in use default locale, implemented by middleware
 */
const defaultLocale = 'en-US'

/** @type Omit<import('next-i18next').UserConfig, "fallbackLng"> & {fallbackLng: string} */
const nexti18nConfig = {
  debug: process.env['DEBUG_I18N'] === 'true',
  i18n: {
    defaultLocale: 'default', // 'default' should not be implemented, but is added to prevent the actual default locale being removed from the URL, which is causing issues as the order cash-frontend is sending and what nextjs serves are different, so i18next is removing the wrong segment
    locales: [...locales, process.env.NODE_ENV === 'production' ? '' : 'en-XA'].filter(locale => locale), // Only include en-XA outside production
  },
  fallbackLng: defaultLocale,
  nonExplicitSupportedLngs: false,
  defaultNS: 'common',
  ns: ['common'],
  contextSeparator: '|░context░|',
  saveMissing: true,
  missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
    const message = `Missing translation in lngs: ${lngs.join(', ')}, namespace: ${ns}, key: ${key}`
    // eslint-disable-next-line no-console
    typeof window === 'undefined' && console.error(message)

    Bugsnag.notify(new Error(`Help: ${message}`))
  },
  serializeConfig: false,
  localePath:
    (process.env['WEBPACK_SERVE'] || process.env['NEXT_PHASE'] === PHASE_PRODUCTION_BUILD) &&
    !process.env['DOCKER_IS_RUNNING_LOCALLY']
      ? path.resolve('./apps/help-center/public/locales')
      : path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
}

module.exports = nexti18nConfig
