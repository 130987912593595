/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {InitiateProductRequestRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {InitiateProductRequestResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const initiateProductRequest = (request:InitiateProductRequestRequest, options?: CashRequestOptions) => post<InitiateProductRequestRequest, InitiateProductRequestResponse>("/2.0/cash/initiate-product-request", request, {...options, rejectOnError: true })