/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSponsorshipRequestAttemptsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetSponsorshipRequestAttemptsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getSponsorshipRequestAttempts = (request:GetSponsorshipRequestAttemptsRequest, options?: CashRequestOptions) => post<GetSponsorshipRequestAttemptsRequest, GetSponsorshipRequestAttemptsResponse>("/2.0/cash/get-sponsorship-request-attempts", request, {...options, rejectOnError: true })