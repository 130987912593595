/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetFamilyAccountsRequest} from '@cash-web/protos/squareup/cash/aegis/api/GetFamilyAccountsRequest.pb'
import {GetFamilyAccountsResponse} from '@cash-web/protos/squareup/cash/aegis/api/GetFamilyAccountsResponse.pb'


export const getFamilyAccounts = (request:GetFamilyAccountsRequest, options?: CashRequestOptions) => post<GetFamilyAccountsRequest, GetFamilyAccountsResponse>("/2.0/cash/get-family-accounts", request, {...options, rejectOnError: true })