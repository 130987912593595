import { ThemeColorsOverride } from './colors'

const bitcoinPalette = {
  ColorPaletteBitcoinLight: '#00d5ff',
  ColorPaletteBitcoinDark: '#00d4ff',
  ColorPaletteBitcoinActive: '#00BDE6',
} as const

const bitcoinColors = {
  active: { light: bitcoinPalette.ColorPaletteBitcoinActive },
  primaryButtonActive: { light: bitcoinPalette.ColorPaletteBitcoinActive },
  primary: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  primaryButtonBackground: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  tint: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
  progressBarFill: {
    light: bitcoinPalette.ColorPaletteBitcoinLight,
    dark: bitcoinPalette.ColorPaletteBitcoinDark,
  },
} as ThemeColorsOverride

export default bitcoinColors
