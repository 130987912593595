import { type Locale as DateFnsLocale } from 'date-fns'

const STATUS_PAGE_URL = 'https://status.cash.app'
const CASH_APP_URL = 'https://cash.app'
const CONTACT_URL = '/contact'

export type NumericDateFormat = 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'YYYY/MM/DD'

export type RegionConfigEntry = {
  phonePrefix: string
  numericDateFormat: NumericDateFormat
  locale: Intl.LocalesArgument
  defaultDateFnsLocale: () => Promise<{ default: DateFnsLocale }>
  links: {
    disclosures: string
    terms: string
    eSignConsent: string
    privacyPolicy: string
  }
  footerLinks?: {
    cashApp: string
    contact: string
    legal: string
    privacy: string
    status: string
    security?: string
  }
  canTrack: boolean
  onlyPushToACH: boolean
  // If you're unsure of this value for a region, default to true.
  showCreateAccountLink: boolean
  // On the first Login screen, show legal text about disclosures.
  showDisclosuresOnLogin?: boolean
  // Controls if the Account app is locked for people when profile is incomplete.
  incompleteProfileLocksAccount: boolean
  instrumentLinking: {
    allowDebitLink: boolean
    allowBankLink: boolean
    allowCreditLink: boolean
  }
}

const privacyPolicyLink = (region: string) => `https://cash.app/legal/${region}/en-${region}/privacy`
const legalTosLink = (region: string) => `https://cash.app/legal/${region}/en-${region}/tos`
const disclosuresLink = (region: string) => `https://cash.app/legal/${region}/en-${region}/disclosures`

const DEFAULT_FOOTER_LINKS = {
  cashApp: CASH_APP_URL,
  contact: CONTACT_URL,
  status: STATUS_PAGE_URL,
  legal: legalTosLink('us'),
  privacy: privacyPolicyLink('us'),
}

export type RegionConfig = Record<string, RegionConfigEntry>

export const regionConfig: RegionConfig = {
  US: {
    phonePrefix: '1',
    numericDateFormat: 'MM/DD/YYYY',
    locale: 'en-US',
    defaultDateFnsLocale: () => import('date-fns/locale/en-US'),
    footerLinks: { ...DEFAULT_FOOTER_LINKS, security: '/security' },
    links: {
      disclosures: disclosuresLink('us'),
      terms: legalTosLink('us'),
      eSignConsent: 'https://cash.app/legal/us/en-us/sign',
      privacyPolicy: privacyPolicyLink('us'),
    },
    canTrack: true,
    onlyPushToACH: false,
    showCreateAccountLink: true,
    showDisclosuresOnLogin: true,
    incompleteProfileLocksAccount: false,
    instrumentLinking: {
      allowDebitLink: true,
      allowBankLink: true,
      allowCreditLink: false,
    },
  },
  GB: {
    phonePrefix: '44',
    numericDateFormat: 'DD/MM/YYYY',
    locale: 'en-GB',
    defaultDateFnsLocale: () => import('date-fns/locale/en-GB'),
    footerLinks: {
      ...DEFAULT_FOOTER_LINKS,
      legal: legalTosLink('gb'),
      privacy: privacyPolicyLink('gb'),
    },
    links: {
      disclosures: disclosuresLink('gb'),
      terms: legalTosLink('gb'),
      eSignConsent: 'https://squareup.com/gb/en/legal/general/sign',
      privacyPolicy: privacyPolicyLink('gb'),
    },
    canTrack: false,
    onlyPushToACH: true,
    showCreateAccountLink: false,
    incompleteProfileLocksAccount: true,
    instrumentLinking: {
      allowDebitLink: false,
      allowBankLink: false,
      allowCreditLink: false,
    },
  },
  CA: {
    phonePrefix: '1',
    numericDateFormat: 'DD/MM/YYYY',
    locale: 'en-CA',
    defaultDateFnsLocale: () => import('date-fns/locale/en-CA'),
    footerLinks: DEFAULT_FOOTER_LINKS,
    links: {
      disclosures: disclosuresLink('ca'),
      terms: legalTosLink('ca'),
      eSignConsent: 'https://squareup.com/ca/en/legal/general/sign',
      privacyPolicy: privacyPolicyLink('ca'),
    },
    canTrack: false,
    onlyPushToACH: true,
    showCreateAccountLink: true,
    incompleteProfileLocksAccount: false,
    instrumentLinking: {
      allowDebitLink: false,
      allowBankLink: false,
      allowCreditLink: false,
    },
  },
  AU: {
    phonePrefix: '61',
    numericDateFormat: 'DD/MM/YYYY',
    locale: 'en-AU',
    defaultDateFnsLocale: () => import('date-fns/locale/en-AU'),
    footerLinks: {
      ...DEFAULT_FOOTER_LINKS,
      legal: legalTosLink('au'),
      privacy: privacyPolicyLink('au'),
    },
    links: {
      disclosures: disclosuresLink('au'),
      terms: legalTosLink('au'),
      eSignConsent: 'https://squareup.com/au/en/legal/general/sign',
      privacyPolicy: privacyPolicyLink('au'),
    },
    canTrack: true,
    onlyPushToACH: true,
    showCreateAccountLink: true,
    incompleteProfileLocksAccount: false,
    instrumentLinking: {
      allowDebitLink: false,
      allowBankLink: false,
      allowCreditLink: false,
    },
  },
  IE: {
    phonePrefix: '353',
    numericDateFormat: 'DD/MM/YYYY',
    locale: 'en-IE',
    defaultDateFnsLocale: () => import('date-fns/locale/en-IE'),
    footerLinks: {
      ...DEFAULT_FOOTER_LINKS,
      legal: legalTosLink('ie'),
      privacy: privacyPolicyLink('ie'),
    },
    links: {
      disclosures: disclosuresLink('ie'),
      terms: legalTosLink('ie'),
      eSignConsent: 'https://squareup.com/ie/en/legal/general/sign',
      privacyPolicy: privacyPolicyLink('ie'),
    },
    canTrack: false,
    onlyPushToACH: true,
    showCreateAccountLink: true,
    incompleteProfileLocksAccount: true,
    instrumentLinking: {
      allowDebitLink: false,
      allowBankLink: false,
      allowCreditLink: false,
    },
  },
}
