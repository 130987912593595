import { createMiddlewareStore } from '../createMiddlewareStore'
import { accessTokenRequest } from './accessToken'
import { clientScenarioRequest } from './clientScenario'
import { csrfTokenRequest, csrfTokenResponse } from './csrfToken'
import { ensureHeadersRequest } from './ensureHeader'
import { flowTokenRequest } from './flowToken'
import { jsonSerializeRequest, jsonSerializeResponse } from './jsonSerialize'
import { optionalRequestHeadersRequest } from './optionalRequestHeaders'
import { recaptchaRequest } from './recaptchaRequest'
import { rejectOnErrorResponse } from './rejectOnError'
import { requestContextRequest } from './requestContext'
import { responseContextResponse } from './responseContext'
import { signatureRequest } from './signature'
import { statusResultResponse } from './statusResult'
import { CashRequest, CashRequestContext, CashResponse, CashResponseContext } from './types'

export const fetchRequestMiddleware = createMiddlewareStore<CashRequest, CashRequestContext>(
  ensureHeadersRequest,
  accessTokenRequest,
  csrfTokenRequest,
  clientScenarioRequest,
  flowTokenRequest,
  requestContextRequest,
  recaptchaRequest,
  jsonSerializeRequest,
  signatureRequest,
  optionalRequestHeadersRequest
)
export const fetchResponseMiddleware = createMiddlewareStore<CashResponse, CashResponseContext>(
  csrfTokenResponse,
  jsonSerializeResponse,
  statusResultResponse,
  responseContextResponse,
  // this should be last as it will throw an error if the response is not ok
  rejectOnErrorResponse
)
