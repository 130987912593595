import { Country } from '@cash-web/protos/squareup/common/countries.pb'
import { useFeatureFlag } from '@cash-web/shared-data-access-feature-flags'

import { useProfileCountry } from '../countryUtils'

export const usePlasmaCardLink = () => {
  const settings = useFeatureFlag('cash-web-account-link-card-plasma-settings')
  const country = useProfileCountry() || Country.ZZ

  const isEnabledInUsersCountry = settings?.countries?.includes(country)

  return isEnabledInUsersCountry || false
}
