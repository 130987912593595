/* eslint-disable no-bitwise */
import { AccountTemplateVariables, WINDOW_VARIABLE_NAME } from '@cash-web/shared-util-environment'

import { get } from './fetch'

/**
 * Creates a flow token which can be used for a single flow. Consumers must create
 * a fresh flow token for every instance of a flow. If consumers reuse a flow token
 * it can frequently result in flows which work the first time, but fail the second
 * time, which can be difficult to detect.
 */
export const createSingleUseFlowToken = (token?: string): string => {
  let flowToken
  if (token) {
    flowToken = token
  } else {
    let d = new Date().getTime()
    flowToken = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }
  return flowToken
}
export type SessionData = {
  accessToken: string
  csrfToken: string
  featureFlagsJson: string
  customerToken: string
}
// Allow connecting development to staging server
export const getServerGeneratedProperties = (path = '/fake-server-properties'): Promise<void> =>
  get<SessionData>(path).then(sessionData => {
    window.csrfToken = sessionData.csrfToken
    window.accessToken = sessionData.accessToken

    const featureFlagsJson = JSON.parse(sessionData.featureFlagsJson)
    if (typeof window.featureFlagsJson === 'object') {
      window.featureFlagsJson = { ...featureFlagsJson, ...window.featureFlagsJson }
    } else {
      window.featureFlagsJson = featureFlagsJson
    }

    const templateVariables = window[WINDOW_VARIABLE_NAME as keyof Window] as AccountTemplateVariables
    if (templateVariables != null) {
      templateVariables.customerToken = sessionData.customerToken
    }
  })
