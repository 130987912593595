/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {UpdateTranslationRequest} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'
import {UpdateTranslationResponse} from '@cash-web/protos/squareup/cash/cashlingo/core/core_service.pb'


export const updateTranslation = (request:UpdateTranslationRequest, options?: CashRequestOptions) => post<UpdateTranslationRequest, UpdateTranslationResponse>("/2.0/cash/update-translation", request, {...options, rejectOnError: true })