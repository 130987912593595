/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetGraduationJourneyEventsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetGraduationJourneyEventsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getGraduationJourneyEvents = (request:GetGraduationJourneyEventsRequest, options?: CashRequestOptions) => post<GetGraduationJourneyEventsRequest, GetGraduationJourneyEventsResponse>("/2.0/cash/get-graduation-journey-events", request, {...options, rejectOnError: true })