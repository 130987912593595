/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSponsorshipLimitsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetSponsorshipLimitsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getSponsorshipLimits = (request:GetSponsorshipLimitsRequest, options?: CashRequestOptions) => post<GetSponsorshipLimitsRequest, GetSponsorshipLimitsResponse>("/2.0/cash/get-sponsorship-limits", request, {...options, rejectOnError: true })