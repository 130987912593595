import { css, Theme } from '@emotion/react'

import { component, semantic } from '@arcade/web'

import { ButtonSize, ButtonVariants } from './ArcadeButton'

interface ThemeColors {
  light: string
  dark: string
}

interface VariantStyles {
  backgroundDefault: ThemeColors
  backgroundDisabled: ThemeColors
  backgroundFocused: ThemeColors
  backgroundHovered: ThemeColors
  backgroundPressed: ThemeColors
  color: ThemeColors
  colorDisabled: ThemeColors
}

const VARIANTS: Record<ButtonVariants, VariantStyles> = {
  prominent: {
    backgroundDefault: component.buttonProminentBackgroundDefault,
    backgroundDisabled: component.buttonProminentBackgroundDisabled,
    backgroundFocused: component.buttonProminentBackgroundDefault, // todo: create focus color pairing
    backgroundHovered: component.buttonProminentBackgroundHover,
    backgroundPressed: component.buttonProminentBackgroundPressed,
    color: component.buttonProminentTextDefault,
    colorDisabled: component.buttonProminentTextDisabled,
  },
  standard: {
    backgroundDefault: component.buttonStandardBackgroundDefault,
    backgroundDisabled: component.buttonStandardBackgroundDisabled,
    backgroundFocused: component.buttonStandardBackgroundDefault,
    backgroundHovered: component.buttonStandardBackgroundHover,
    backgroundPressed: component.buttonStandardBackgroundPressed,
    color: component.buttonStandardTextDefault,
    colorDisabled: component.buttonStandardTextDisabled,
  },
  subtle: {
    backgroundDefault: component.buttonSubtleBackgroundDefault,
    backgroundDisabled: component.buttonSubtleBackgroundDisabled,
    backgroundFocused: component.buttonSubtleBackgroundDefault,
    backgroundHovered: component.buttonSubtleBackgroundFocus,
    backgroundPressed: component.buttonSubtleBackgroundPressed,
    color: component.buttonSubtleTextDefault,
    colorDisabled: component.buttonSubtleTextDisabled,
  },
  destructive: {
    backgroundDefault: component.buttonDestructiveProminentBackgroundDefault,
    backgroundDisabled: component.buttonDestructiveProminentBackgroundDisabled,
    backgroundFocused: component.buttonDestructiveProminentBackgroundDefault, // todo: create focus color pairing
    backgroundHovered: component.buttonDestructiveProminentBackgroundPressed,
    backgroundPressed: component.buttonDestructiveProminentBackgroundPressed,
    color: component.buttonDestructiveProminentTextDefault,
    colorDisabled: component.buttonDestructiveProminentTextDisabled,
  },
}

const getSizeStyles = ({ padding, fontSize }: { padding: string; fontSize: string }) => css`
  padding: ${padding};
  font-size: ${fontSize};
  height: auto;
  max-height: auto;
`

export const focusRingStyles = ({ theme }: { theme: Theme }) => {
  const { darkMode, selectors, spacing, pxToRem } = theme
  const scheme = darkMode ? 'dark' : 'light'
  const focusRingColor = semantic.borderProminent[scheme]

  /*
   * Generates outline (focus ring) using pseudo-element for Safari compatibility
   * Safari outline bug details here: https://bugs.webkit.org/show_bug.cgi?id=20807
   */
  return css`
    ${selectors.keyboard} &:focus::before {
      content: '';
      position: absolute;
      inset: -${spacing.xxs} -${spacing.xxs} -${spacing.xxs} -${spacing.xxs};
      border: ${pxToRem(1.5)} solid ${focusRingColor};
      border-radius: 3rem;
    }
  `
}

interface StyleProps {
  functionallyDisabled?: boolean
  visuallyDisabled?: boolean
  theme: Theme
  variant?: ButtonVariants
  size?: ButtonSize
}

/**
 * Generates the CSS styles for a button based on the provided style props.
 *
 * @param {StyleProps} props - The style props for the button.
 * @returns {string} The generated CSS styles for the button.
 */
export const getVariantStyles = ({ functionallyDisabled, visuallyDisabled, theme, variant, size }: StyleProps) => {
  const variantStyles = (variant && VARIANTS[variant]) ?? VARIANTS['standard']
  const { addOpacity, darkMode, motion, spacing } = theme
  const scheme = darkMode ? 'dark' : 'light'

  const styles = [
    // size styles
    (() => {
      switch (size) {
        case 'compact':
          return getSizeStyles({ padding: `${spacing.xxs} ${spacing.m}`, fontSize: '1rem' })
        default:
          return null
      }
    })(),

    // variant-specific styles
    css`
      background: ${variantStyles.backgroundDefault[scheme]};
      color: ${variantStyles.color[scheme]};

      ${visuallyDisabled &&
      css`
        background: ${variantStyles.backgroundDisabled[scheme]};
        color: ${addOpacity(variantStyles.colorDisabled[scheme], 0.75)};
        cursor: not-allowed;
      `}

      ${!functionallyDisabled &&
      css`
        @media (hover: hover) {
          :hover {
            background: ${variantStyles.backgroundHovered[scheme]};
          }
        }
        :active {
          background: ${variantStyles.backgroundPressed[scheme]};
          ${size === 'compact' ? motion.standardActiveState : motion.standardSubtleActiveState};
        }
        :focus {
          background: ${variantStyles.backgroundFocused[scheme]};
        }
      `}
    `,
  ]

  return css`
    ${styles.filter(Boolean)}
  `
}
