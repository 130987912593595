import Bugsnag from '@bugsnag/js'

import { getEnvironmentAtRunTime } from '@cash-web/shared-util-environment'
import { get } from '@cash-web/shared-util-fetch'

const BASE_URL = getEnvironmentAtRunTime() === 'production' ? 'https://signal.cash.app' : 'https://cashstaging.app'

export async function trackRiskSignal(): Promise<void> {
  // This will error if the component using this does not have a FeatureFlagProvider
  try {
    await get(`${BASE_URL}/pixel.png`)
  } catch (e) {
    Bugsnag.notify(`Failed to get risk signal: ${JSON.stringify(e)}`)
  }
}
