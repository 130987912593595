/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetDependentCustomerDataRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetDependentCustomerDataResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getDependentCustomerData = (request:GetDependentCustomerDataRequest, options?: CashRequestOptions) => post<GetDependentCustomerDataRequest, GetDependentCustomerDataResponse>("/2.0/cash/get-dependent-customer-data", request, {...options, rejectOnError: true })