import { Dimmer, Modal } from '@cash-design-system/react'

import QRContent, { type Props as QRContentProps } from '../QRContent'

export type Props = {
  open?: boolean
  onClose: () => void
} & QRContentProps

interface IqrHashes {
  [string: string]: string
}

export const qrHashes: IqrHashes = {
  chat: '#chat',
}

// Note: I am moving the 'open' prop in here as I would like to nicely animate this in the future
export default function QRModal({ open = false, onClose, ...qrProps }: Props) {
  return open ? (
    <Dimmer onClick={onClose} data-testid="qr-modal-dimmer">
      <Modal onClose={onClose}>
        <QRContent {...qrProps} />
      </Modal>
    </Dimmer>
  ) : null
}
