import type { Config } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { getPublicRuntimeConfig } from '../config/nextConfig'

export function start() {
  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE

  const { bugsnagApi } = getPublicRuntimeConfig()

  // Skip logger debugging on Cypress
  const logger: Config['logger'] =
    process.env['NX_TASK_TARGET_PROJECT'] == 'help-center-e2e'
      ? {
          debug: () => null,
          info: () => null,
          warn: args => {
            console.warn('Bugsnag warning:', args) // eslint-disable-line no-console
          },
          error: args => {
            console.error('Bugsnag error:', args) // eslint-disable-line no-console
          },
        }
      : undefined

  if (process.env['NEXT_PHASE'] !== 'phase-production-build') {
    if (process.env['NEXT_IS_SERVER'] === 'true') {
      // eslint-disable-next-line no-restricted-syntax
      Bugsnag.start({
        apiKey: bugsnagApi,
        appVersion: process.env['NEXT_BUILD_ID'],
        logger,
        // @bugsnag/plugin-aws-lambda must only be imported on the server
        plugins: [require('@bugsnag/plugin-aws-lambda')],
      })
    } else {
      // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
      // eslint-disable-next-line no-restricted-syntax
      Bugsnag.start({
        apiKey: bugsnagApi,
        appVersion: process.env['NEXT_BUILD_ID'],
        logger,
        plugins: [new BugsnagPluginReact()],
      })
    }
  }
}

export function getServerlessHandler() {
  return Bugsnag.getPlugin('awsLambda').createHandler()
}
