import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Header, Text } from '@cash-design-system/react'

export type Props = {
  title?: string
  subtitle?: React.ReactNode
  altText?: string
  size?: number
  qrPath: string
  theme?: QrThemes
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const Image = styled.img`
  align-self: center;
`

const DEFAULT_QR_SIZE = 300

export enum QrThemes {
  LIGHT = 'default',
  DARK = 'dark',
  THEMED = 'themed',
}

export default function QRContent({
  title,
  subtitle,
  qrPath,
  theme = QrThemes.THEMED,
  size = DEFAULT_QR_SIZE,
  altText = title,
}: Props) {
  const { darkMode } = useTheme()

  const color = theme === QrThemes.THEMED ? (darkMode ? QrThemes.DARK : QrThemes.LIGHT) : theme

  const imageSrc = `${qrPath}?size=${size}&color=${color}`
  return (
    <Container>
      {title && <Header variant="tabTitle">{title}</Header>}
      {subtitle && <Text>{subtitle}</Text>}
      <Image src={imageSrc} alt={altText} height={size} width={size} />
    </Container>
  )
}
