// import Bugsnag from '@bugsnag/js'
// import logger from '@cash-web/shared/util-logger'

import { ClientScenario } from '@cash-web/protos/squareup/franklin/api/client_scenario.pb'

import { CashRequestMiddleware } from './types'

declare global {
  interface Window {
    recaptchaKeyId?: string
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void
        execute: (key: string, options: { action: string }) => Promise<string>
      }
    }
  }
}
export const recaptchaRequest: CashRequestMiddleware = async (request, context) => {
  if (typeof window !== 'undefined' && !window.recaptchaKeyId) {
    return request
  }
  const headers = request.headers as Record<string, unknown>
  const ClientScenarioHeader = headers['Cash-Client-Scenario']
  const isOnboarding = ClientScenarioHeader === ClientScenario.ONBOARDING
  const isLogin = ClientScenarioHeader === ClientScenario.LOGIN
  const isVerifyEmail = context?.path === '/2.0/cash/verify-email'
  const isVerifySms = context?.path === '/2.0/cash/verify-sms'
  if ((isOnboarding || isLogin) && (isVerifyEmail || isVerifySms)) {
    if (typeof window !== 'undefined' && window.grecaptcha?.enterprise) {
      const token = await new Promise(resolve => {
        window.grecaptcha.enterprise.ready(async () => {
          try {
            resolve(
              await window.grecaptcha.enterprise.execute(window.recaptchaKeyId || '', {
                action: ClientScenarioHeader,
              })
            )
          } catch (e) {
            // TODO: add logging that doesn't create test failures (bgando)
            // logger.error(new Error(`recaptcha failed ${e}`))
            // Bugsnag.notify(new Error(`recaptcha failed ${e}`))
            resolve(undefined)
          }
        })
      })
      if (token) {
        headers['x-recaptcha-token'] = token
      }
    }
  }
  return request
}
