/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {SearchPublicSupportArticlesRequest} from '@cash-web/protos/squareup/cash/supportarticles/api/v1beta1/service.pb'
import {SearchPublicSupportArticlesResponse} from '@cash-web/protos/squareup/cash/supportarticles/api/v1beta1/service.pb'


export const searchPublicSupportArticles = (request:SearchPublicSupportArticlesRequest, options?: CashRequestOptions) => post<SearchPublicSupportArticlesRequest, SearchPublicSupportArticlesResponse>("/2.0/cash/search-public-support-articles", request, {...options, rejectOnError: true })