/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AddGraduationEntryPointsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {AddGraduationEntryPointsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const addGraduationEntryPoints = (request:AddGraduationEntryPointsRequest, options?: CashRequestOptions) => post<AddGraduationEntryPointsRequest, AddGraduationEntryPointsResponse>("/2.0/cash/add-graduation-entry-points", request, {...options, rejectOnError: true })