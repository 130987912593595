import React from 'react'

import { useUniqueDef } from '../utils/useUniqueDef'

const CloseIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  const def = useUniqueDef()
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={def.url}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.31 8.19a1.5 1.5 0 00-2.12 2.12L13.878 16l-5.69 5.69a1.5 1.5 0 002.122 2.12L16 18.122l5.69 5.69a1.5 1.5 0 002.12-2.122L18.122 16l5.69-5.69a1.5 1.5 0 00-2.122-2.12L16 13.878l-5.69-5.69z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={def.id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CloseIcon
