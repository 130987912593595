import { useEffect, useMemo, useState } from 'react'

import { isServerEnvironment } from '@cash-web/shared-util-environment'

export default isServerEnvironment()
  ? () => false
  : function useMediaQuery(query: string): boolean {
      const media = useMemo(() => window.matchMedia(query.replace('@media', '').trim()), [query])
      const [matches, setMatches] = useState(media.matches)

      useEffect(() => {
        setMatches(media.matches)

        const listener = (event: MediaQueryListEvent) => {
          setMatches(event.matches)
        }

        if (media.addEventListener) {
          media.addEventListener('change', listener)
        } else {
          media.addListener(listener)
        }

        return () => {
          if (media.removeEventListener) {
            media.removeEventListener('change', listener)
          } else {
            media.removeListener(listener)
          }
        }
      }, [media, setMatches])

      return matches
    }
