import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { LinkAdapterProps } from '../../types/types'
import { CashLogo } from './CashLogo'

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.marketingLabel};
  ${props => props.theme.typography.tabTitle};
  font-weight: 500;
`

const SupportLogo = <T,>({ Link, linkProps }: LinkAdapterProps<T>) => {
  const StyledLink = styled(Link)`
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 11px;
    text-decoration: none;
    color: inherit;
    align-items: center;
  `

  const { t } = useTranslation()
  return (
    <StyledLink {...linkProps}>
      <CashLogo data-testid="cash-support-logo" />
      <StyledSpan>{t('Cash App Support', { context: 'Support title as part of the logo' })}</StyledSpan>
    </StyledLink>
  )
}

export default SupportLogo
