import styled from '@emotion/styled'

import CloseIcon from '../../icons/CloseIcon'

const ModalHeaderRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.m};
  background: inherit;
  top: 0;

  ${props => props.theme.breakpoints.tablet} {
    padding: ${({ theme }) => theme.spacing.l};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }
`

const CloseButton = styled.button`
  appearance: none;
  cursor: pointer;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: inherit;
  border: none;
  outline-style: none;
  padding: ${({ theme }) => theme.spacing.empty};
  color: ${props => props.theme.colors.icon};
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  :disabled {
    cursor: not-allowed;
  }

  ${props => props.theme.selectors.keyboard} &:focus {
    outline-style: auto;
  }
`

export interface ModalHeaderProps {
  disableClose?: boolean
  onClose: () => void
  'aria-label'?: string
}

const ModalHeader = ({ disableClose, onClose, 'aria-label': ariaLabel = 'close' }: ModalHeaderProps) => (
  <ModalHeaderRow>
    <CloseButton disabled={disableClose} onClick={disableClose ? () => null : onClose} aria-label={ariaLabel}>
      <CloseIcon fontSize="2rem" />
    </CloseButton>
  </ModalHeaderRow>
)

export default ModalHeader
