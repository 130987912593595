/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSponsorshipApprovalAttemptsRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetSponsorshipApprovalAttemptsResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getSponsorshipApprovalAttempts = (request:GetSponsorshipApprovalAttemptsRequest, options?: CashRequestOptions) => post<GetSponsorshipApprovalAttemptsRequest, GetSponsorshipApprovalAttemptsResponse>("/2.0/cash/get-sponsorship-approval-attempts", request, {...options, rejectOnError: true })