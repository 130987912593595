/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {MakeDobGraduationJourneyEventRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {MakeDobGraduationJourneyEventResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const makeDobGraduationJourneyEvent = (request:MakeDobGraduationJourneyEventRequest, options?: CashRequestOptions) => post<MakeDobGraduationJourneyEventRequest, MakeDobGraduationJourneyEventResponse>("/2.0/cash/make-dob-graduation-journey-event", request, {...options, rejectOnError: true })