import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { Key } from 'react'

import { border } from '@arcade/web'
import { spacing } from '@cash-design-system/react'

type ColumnDefinition = { mobile: number; tablet: number; desktop: number }
type StyleProps = {
  radius: string
  divider: boolean
  columns: ColumnDefinition
}

type Props<T> = Partial<Omit<StyleProps, 'columns'>> & {
  data: T[]
  render: (props: T & { index: number }) => React.ReactNode
  extractKey?: (props: T, index: number) => Key
  columns?: number | Partial<ColumnDefinition>
}

const ColumnStyles = (props: { theme: Theme } & StyleProps, columnCount: number) => {
  return css`
    display: grid;
    grid-template-columns: repeat(${columnCount}, 1fr);
    clip-path: ${columnCount <= 1 ? `inset(0 round ${props.radius})` : 'none'};
    gap: ${columnCount <= 1 ? '0em' : spacing.s};

    li {
      border-bottom: ${props.divider && columnCount <= 1 ? `1px solid ${props.theme.colors.hairline}` : 'none'};
      clip-path: ${columnCount > 1 ? `inset(0 round ${props.radius})` : 'none'};

      &:last-of-type {
        border-bottom: none;
      }
    }
  `
}

const CutoutList = styled.ul<StyleProps>`
  ${props => ColumnStyles(props, props.columns.mobile)}
  ${props => props.theme.breakpoints.tablet} {
    ${props => ColumnStyles(props, props.columns.tablet)}
  }
  ${props => props.theme.breakpoints.desktop} {
    ${props => ColumnStyles(props, props.columns.desktop)}
  }
`

function getColumnDefinition(columns: number | Partial<ColumnDefinition>): ColumnDefinition {
  if (typeof columns === 'number') {
    return { mobile: columns, tablet: columns, desktop: columns }
  }
  const mobile = columns.mobile ?? 1
  const tablet = columns.tablet ?? mobile
  const desktop = columns.desktop ?? tablet
  return {
    mobile,
    tablet,
    desktop,
  }
}

export default function CardList<T>({
  data,
  render,
  extractKey = (_, index) => index,
  columns = 1,
  divider = false,
  radius = border.radius.medium,
}: Props<T>) {
  return (
    <CutoutList divider={divider} radius={radius} columns={getColumnDefinition(columns)}>
      {data.map((p, index) => (
        <li key={extractKey(p, index)}>{render({ ...p, index })}</li>
      ))}
    </CutoutList>
  )
}
