/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RequestSponsorshipRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {RequestSponsorshipResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const requestSponsorship = (request:RequestSponsorshipRequest, options?: CashRequestOptions) => post<RequestSponsorshipRequest, RequestSponsorshipResponse>("/2.0/cash/request-sponsorship", request, {...options, rejectOnError: true })