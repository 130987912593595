import Cookie from 'js-cookie'

type Unsubscribe = () => void

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cookieStore: any
  }
}

export function createPreferenceManager<TPreferences extends Record<string, unknown>>(defaults: TPreferences) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listeners = new Map<keyof TPreferences, Set<(value: any) => void>>()

  function getPreferenceCookieName<K extends keyof TPreferences>(preference: K) {
    return `cash-web-preference--${String(preference)}`
  }

  function getPreferenceChangeEventName<K extends keyof TPreferences>(preference: K) {
    return `cash-web-preference-change--${String(preference)}`
  }

  function getPreferenceListeners<K extends keyof TPreferences>(preference: K) {
    if (!listeners.has(preference)) {
      listeners.set(preference, new Set())
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return listeners.get(preference)!
  }

  function getPreference<K extends keyof TPreferences>(preference: K): TPreferences[K] {
    try {
      const serialized = Cookie.get(getPreferenceCookieName(preference))
      return serialized == null ? defaults[preference] : JSON.parse(serialized)
    } catch {
      return defaults[preference]
    }
  }

  function setPreference<K extends keyof TPreferences>(preference: K, value: TPreferences[K]): void {
    const rootDomain = new URL(window.location.href).hostname.split('.').slice(-2).join('.')

    Cookie.set(getPreferenceCookieName(preference), JSON.stringify(value), {
      domain: rootDomain,
      path: '/',
      sameSite: 'Strict',
      secure: true,
      expires: (() => {
        const expiration = new Date()
        expiration.setFullYear(expiration.getFullYear() + 5)
        return expiration
      })(),
    })

    localStorage.setItem(getPreferenceChangeEventName(preference), String(Date.now()))

    getPreferenceListeners(preference).forEach(notify => notify(value))
  }

  function onPreferenceChange<K extends keyof TPreferences>(
    preference: K,
    callback: (value: TPreferences[K]) => void
  ): Unsubscribe {
    const onCookieChange = (event: { changed: [{ name: string }] }) => {
      if (event?.changed?.some(cookie => cookie?.name === getPreferenceCookieName(preference))) {
        callback(getPreference(preference))
      }
    }

    const onStorage = ({ key }: StorageEvent) => {
      if (key === getPreferenceChangeEventName(preference)) {
        callback(getPreference(preference))
      }
    }

    // 1. CookieStore not supported in all browsers, but most reliable cross subdomain notifier
    window.cookieStore?.addEventListener?.('change', onCookieChange)

    // 2. fallback to a storage event to at least sync between tabs on same subdomain
    window.addEventListener('storage', onStorage)

    // 3. listens for in-memory updates
    getPreferenceListeners(preference).add(callback)

    return () => {
      window.cookieStore?.removeEventListener?.('change', onCookieChange)
      window.removeEventListener('storage', onStorage)
      getPreferenceListeners(preference).delete(callback)
    }
  }

  return { setPreference, getPreference, onPreferenceChange }
}
