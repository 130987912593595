/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPreviouslyActiveDependentsForSponsorRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetPreviouslyActiveDependentsForSponsorResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getPreviouslyActiveDependentsForSponsor = (request:GetPreviouslyActiveDependentsForSponsorRequest, options?: CashRequestOptions) => post<GetPreviouslyActiveDependentsForSponsorRequest, GetPreviouslyActiveDependentsForSponsorResponse>("/2.0/cash/get-previously-active-dependents-for-sponsor", request, {...options, rejectOnError: true })