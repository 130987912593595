import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

export const LinkAndAnchorStyles = (props: { theme: Theme }) => css`
  text-decoration: none;

  :hover *,
  :focus * {
    outline-color: transparent;
    background-color: ${props.theme.colors.secondaryButtonHover};
  }

  :focus {
    outline-color: transparent;
  }
`

export const StyledStructuredContentForArticle = (props: { theme: Theme }) => css`
  margin: ${props.theme.spacing.empty};

  > * {
    max-width: 100%;
  }

  h2 {
    ${props.theme.typography.sectionTitle}
  }

  h3,
  h4,
  h5 {
    ${props.theme.typography.pageTitle}
  }

  ${props.theme.typography.body}
  line-height: 1.7;
  @media (min-width: ${props.theme.breakpoints.tablet}) {
    ${props.theme.typography.body}
    line-height: 1.7;
  }

  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }

  img {
    max-height: 600px;
  }

  ol,
  ul {
    // These padding changes are so that numbered lists and bulleted
    // lists line up. We are using "em" rather than "rem" so we can
    // have the padding amount increased as the parent's font size increases
    // to leave enough room for the number

    padding-left: 1.5em;

    li {
      padding-left: 0.5em;
    }
  }

  ol {
    list-style: none;
    counter-reset: list;

    & > li {
      counter-increment: list;
      display: flex;
      align-items: center;
      white-space: pre; // render leading & trailing whitespace so inline-blocks contain proper spacing

      &:before {
        content: counter(list);
        position: relative;
        display: inline-block;

        // font size a bit smaller
        font-size: 0.75em;
        font-weight: ${props.theme.weights.bold};

        // creates a box around the number
        background: ${props.theme.colors.secondaryButtonBackground};
        border-radius: 33.3%;
        line-height: 1.5em;
        width: 1.5em;

        // Move the number box to left
        left: -1em;
        top: 0;
        // Essentually removes the space left by relative pos
        margin-left: -1.5em;

        // align to center
        text-align: center;
      }
    }
  }
`
