import * as React from 'react'

import styled from '@emotion/styled'

import { useArcadeMigrationFeatureFlag } from '../ArcadeComponentMigration/ArcadeComponentMigrationProvider'
import ArcadeButton, { ButtonSize as ArcadeButtonSize, ButtonVariants as ArcadeButtonVariants } from './ArcadeButton'
import MooncakeButton, {
  ButtonProps,
  ButtonSize as MooncakeButtonSize,
  ButtonVariants as MooncakeButtonVariants,
} from './MooncakeButton'

export const Buttonless = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  text-align: left;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.empty};
  user-select: none;
  min-height: 4rem;
  line-height: 2rem;
  vertical-align: middle;

  ${({ theme }) => theme.selectors.keyboard} &:focus {
    border-radius: 0.5rem;
    outline: 0.5rem solid ${({ theme }) => theme.addOpacity(theme.colors.primary, 0.2)};
  }
`

const mooncakeToArcadeVariantMap: Partial<Record<MooncakeButtonVariants, ArcadeButtonVariants>> = {
  primary: 'prominent',
  secondary: 'standard',
  tertiary: 'subtle',
  'tertiary-outline': 'subtle',
  outline: 'subtle',
  error: 'destructive',
}

const mooncakeToArcadeSizeMap: Record<MooncakeButtonSize, ArcadeButtonSize> = {
  large: 'large',
  medium: 'default',
  small: 'compact',
  icon: 'compact',
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const shouldUseArcadeButton = useArcadeMigrationFeatureFlag('button')

  if (shouldUseArcadeButton) {
    const { variant, size, ...rest } = props

    const arcadeVariant = variant && mooncakeToArcadeVariantMap[variant]
    const arcadeSize = size && mooncakeToArcadeSizeMap[size]

    return <ArcadeButton ref={ref} variant={arcadeVariant} size={arcadeSize} {...rest} />
  }

  return <MooncakeButton ref={ref} {...props} />
})

export default Button
