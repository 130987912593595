import styled from '@emotion/styled'

import { Content, spacing } from '@cash-design-system/react'

import type { LinkAdapterProps } from '../../types/types'
import SupportLogo from '../SupportLogo'

const Container = styled.header`
  display: flex;
  align-items: center;
  padding-top: ${spacing.m};
  padding-bottom: ${spacing.m};
  box-shadow: ${props => props.theme.dropShadows.topBar};
  z-index: 100;
`

const StyledContent = styled(Content)`
  margin: auto;
`

const Header = <T,>(props: LinkAdapterProps<T>) => {
  return (
    <Container>
      <StyledContent>
        <SupportLogo Link={props.Link} linkProps={props.linkProps} />
      </StyledContent>
    </Container>
  )
}

export default Header
