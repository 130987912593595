/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {CancelSponsorshipRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {CancelSponsorshipResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const cancelSponsorship = (request:CancelSponsorshipRequest, options?: CashRequestOptions) => post<CancelSponsorshipRequest, CancelSponsorshipResponse>("/2.0/cash/cancel-sponsorship", request, {...options, rejectOnError: true })