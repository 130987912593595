import React, { useEffect, useRef, useState } from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { spacing } from '../../theme/index'
import ModalHeader from './ModalHeader'

const popUp = keyframes`
    0% {
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
    }
`

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  animation: ${popUp} 200ms forwards;
  background: ${props => props.theme.colors.background};
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: 1rem solid ${props => props.theme.colors.background};

  ${props => props.theme.breakpoints.tablet} {
    max-width: 39rem;
    height: auto;
    max-height: 80vh;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: 1.5rem;
    overflow: hidden;
    box-shadow: ${props => props.theme.dropShadows.dialog};
    outline: 0;
  }
`

const PinnedContentContainer = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing.m} ${spacing.l}`};
  padding-top: ${({ theme }) => theme.spacing.xs};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${({ theme: { spacing } }) => `${spacing.m} 4rem`};
    padding-top: ${({ theme }) => theme.spacing.xs};
  }
`

const ScrollableContent = styled.div<{ contentPadding?: string; pinnedContent?: unknown }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding: ${({ theme: { spacing } }) => `${spacing.m} ${spacing.xl} 2.5rem`};
  position: relative;
  flex-grow: 1;

  ${props => props.theme.typography.body}

  ${props => props.theme.breakpoints.tablet} {
    padding: ${spacing.headerSpacing};
    padding-bottom: ${props => (props.pinnedContent ? spacing.l : spacing.defaultContent)};
    padding-top: 0;
    padding: ${props => props.contentPadding};
  }

  padding: ${props => props.contentPadding};
`

const ShadowPosition = styled.div`
  position: relative;
  height: 0;
  pointer-events: none;
`

const ShadowClip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  overflow: hidden;
`

const Shadow = styled.div<{ active: boolean }>`
  width: 100%;
  left: 0;
  right: 0;
  top: -5rem;
  height: 5rem;
  position: absolute;
  transition: opacity 100ms;
  box-shadow: ${props => props.theme.dropShadows.topBar};
  opacity: ${props => (props.active ? 1 : 0)};
`

const ScrollDetector = styled.div`
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
`

export interface ModalProps {
  embedded?: boolean
  contentPadding?: string
  disableClose?: boolean
  onClose?: () => void
  pinnedContent?: React.ReactNode
  scrollable?: boolean
  showClose?: boolean
  children: React.ReactNode
}

function useScrolled() {
  const target = useRef<HTMLElement | null>()
  const root = useRef<HTMLElement | null>()
  const [hasScrolled, setScrolled] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => setScrolled(!entries[0].isIntersecting), {
      root: root.current,
    })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    observer.observe(target.current!)
    return () => observer.disconnect()
  }, [])

  return { hasScrolled, root, target }
}

const Modal: React.FC<ModalProps> = ({
  children,
  disableClose,
  embedded,
  contentPadding,
  onClose = () => null,
  showClose = true,
  pinnedContent,
  scrollable = true,
}) => {
  const { hasScrolled, target, root } = useScrolled()

  return (
    <Container
      ref={ref => (root.current = ref)}
      onClick={event => event.stopPropagation()}
      aria-modal="true"
      role="dialog"
    >
      {showClose && !embedded && <ModalHeader disableClose={disableClose} onClose={onClose} />}
      {pinnedContent && <PinnedContentContainer>{pinnedContent}</PinnedContentContainer>}
      <ShadowPosition>
        <ShadowClip>
          <Shadow active={hasScrolled} />
        </ShadowClip>
      </ShadowPosition>
      <ScrollableContent contentPadding={contentPadding} pinnedContent={pinnedContent}>
        <ScrollDetector ref={ref => (target.current = ref)} />
        <div style={{ flexGrow: 1, overflowY: scrollable ? undefined : 'hidden' }}>{children}</div>
      </ScrollableContent>
    </Container>
  )
}

export default Modal
