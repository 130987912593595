import { useCallback } from 'react'

import { useRegion } from '@cash-web/shared-feature-region-provider'

import { logWebEvent } from './eventstream2'

type EventData = {
  action: string
  description?: string
  detail?: string
  category?: string
  amount?: number
}

/**
 * @deprecated Use `useLogCashWebCdfEvent` instead. ES2 is deprecated and is slated
 *             to be removed by Q4 2023.
 */
export function useLogEventstream(hostName?: string): (data: EventData) => Promise<void> {
  const regionCode = useRegion()
  return useCallback(
    async ({
      action,
      description,
      detail,
      category, // This is frequently the clientScenario.
      amount,
    }: EventData) => {
      await logWebEvent(
        action,
        {
          cash_web_event_description: description,
          cash_web_event_detail: detail,
          cash_web_event_category: category,
          cash_web_event_amount: amount,
        },
        regionCode,
        hostName
      )
    },
    [regionCode, hostName]
  )
}
