import { useEffect } from 'react'

import {
  Colors,
  getColorsByThemeVariant,
  Provider as DesignSystemProvider,
  ThemeVariant,
} from '@cash-design-system/react'
import { useFeatureFlag } from '@cash-web/shared-data-access-feature-flags'
import { useLocalStorage } from '@cash-web/shared-ui-use-storage'

import { useDarkMode } from '../hooks/useDarkMode'

const BACKGROUND_FALLBACK_KEY = 'cash-web-bg-fallback-color'

type ThemeProviderProps = {
  bodyBackground?: Colors
  optOutOfDarkMode?: boolean
  themeVariant?: ThemeVariant
}

export const ThemeProvider = ({
  bodyBackground = undefined,
  children,
  optOutOfDarkMode = false,
  themeVariant = 'default',
}: React.PropsWithChildren<ThemeProviderProps>) => {
  const isDarkMode = useDarkMode()

  // For the arcade component migration, we need to know which components are enabled.
  // We can't (and shouldn't) use the `useFeatureFlag` hook in CDS-React components directly due to Nx boundaries
  // We pass the feature flags to the `DesignSystemProvider` and the `DesignSystemProvider` will pass it down to the `ArcadeMigrationProvider`
  const arcadeComponentMigrationFeatureFlags = useFeatureFlag('cash-web-arcade-migration')

  // if `optOutOfDarkMode` is true, we don't want to apply dark mode regardless of the user's preference
  // fallback to `isDarkMode` if not opted out
  const shouldUseDarkMode = optOutOfDarkMode ? false : isDarkMode

  const { background } = getColorsByThemeVariant({ themeVariant, darkMode: shouldUseDarkMode })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setBackgroundColor] = useLocalStorage(BACKGROUND_FALLBACK_KEY, background)

  useEffect(() => {
    setBackgroundColor(background)
  }, [background, setBackgroundColor])

  return (
    <DesignSystemProvider
      bodyBackground={bodyBackground}
      darkMode={shouldUseDarkMode}
      themeVariant={themeVariant}
      featureFlags={arcadeComponentMigrationFeatureFlags}
      fonts={false}
    >
      {children}
    </DesignSystemProvider>
  )
}
