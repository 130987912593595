import getConfig from 'next/config'

type PublicConfig = {
  bugsnagApi: string
  bookshelfUrl: string
  contactArticleId: string
  frontendServiceUrl: string
  cfsMeshUrl: string
  statusPageApiUrl: string
  statusPageUrl: string
  deepLinkPath: (action: string) => string
  qrPath: (link: string) => string
}

export function getPublicRuntimeConfig(): PublicConfig {
  const {
    publicRuntimeConfig: {
      bookshelfUrl,
      contactArticleId,
      frontendServiceUrl,
      cfsMeshUrl,
      statusPageApiUrl,
      statusPageUrl,
      bugsnagApi,
      deeplinkPathPrefix,
      qrPathPrefix,
    },
  } = getConfig()

  return {
    bookshelfUrl,
    contactArticleId,
    frontendServiceUrl,
    cfsMeshUrl,
    statusPageApiUrl,
    statusPageUrl,
    bugsnagApi,
    deepLinkPath: action => `${deeplinkPathPrefix}${action}`,
    qrPath: link => `${qrPathPrefix}${link}`,
  } as PublicConfig
}
