export type FeatureFlagDefaultStringVariant = 'UNASSIGNED' | 'ENABLED' | 'DISABLED'

type TimelineStage =
  | 'PRE_SEASON'
  | 'TAX_SEASON_START'
  | 'EMPLOYER_FORMS_SENT'
  | 'INVESTMENT_FORMS_SENT'
  | 'EFILE_DEADLINE'
  | 'POST_SEASON'

export type TaxesArticleName =
  | 'how-to-check-your-refund'
  | 'refund-tips'
  | 'things-to-know'
  | 'what-goes-into-your-refund'
  | 'whats-new'
  | 'what-to-do-if-you-owe'
  | 'why-cash-app-taxes'

export type ArcadeComponentMigrationFlags = 'cash-web-arcade-migration-button' | 'cash-web-arcade-migration-input'
export type ArcadeComponentMigrationConfig = { [K in ArcadeComponentMigrationFlags]: FeatureFlagDefaultStringVariant }

export enum SeverityLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type OutageBannerIncident = {
  details?: string
  id?: string
  reportedAt?: number
  severityLevel?: SeverityLevel
  status?: string
  title?: string
}

export type Incidents = {
  incidents?: OutageBannerIncident[]
}

export type AccountSwitcherVersion = {
  minAndroidAppVersion: string
  minIOSAppVersion: string
}

type RumConfig = { sessionSampleRateOverride: number }

export type FeatureFlags = {
  'cash-frontend-admin-rollouts': boolean
  'cash-frontend-product-resend-code': boolean
  'cash-frontend-service-default-outage-status-message': string
  'cash-frontend-tax-upgrade': boolean
  'cash-web-3ds-redirect-url-test': string
  'cash-web-account-activity-download': boolean
  'cash-web-account-activity-filter-domain-type': FeatureFlagDefaultStringVariant
  'cash-web-account-address-autocomplete': FeatureFlagDefaultStringVariant
  'cash-web-account-allow-international-p2p': FeatureFlagDefaultStringVariant
  'cash-web-account-bills-experience': boolean
  'cash-web-account-bitcoin-limits': boolean
  'cash-web-account-card-studio': FeatureFlagDefaultStringVariant
  'cash-web-account-datadog-rum-config': RumConfig
  'cash-web-account-datadog-rum-enabled': boolean
  'cash-web-account-filter-money-movement': FeatureFlagDefaultStringVariant
  'cash-web-account-initiate-cash-out-endpoint': FeatureFlagDefaultStringVariant
  'cash-web-account-link-card-plasma-settings': { countries?: ReadonlyArray<string> }
  'cash-web-account-new-customer-registration': FeatureFlagDefaultStringVariant
  'cash-web-account-onboarding-validator': 'UNASSIGNED' | 'REQUIRE_REGION_ONLY' | 'REQUIRE_ALL'
  'cash-web-account-p2p-payment-flow-client-scenario': FeatureFlagDefaultStringVariant
  'cash-web-account-paychecks': FeatureFlagDefaultStringVariant
  'cash-web-account-sam': FeatureFlagDefaultStringVariant
  'cash-web-account-settings-instrument-link-overrides': {
    allowBankLink?: boolean
    allowCreditLink?: boolean
    allowDebitLink?: boolean
  }
  'cash-web-account-settings-show-devices': boolean
  'cash-web-account-show-account-number': FeatureFlagDefaultStringVariant
  'cash-web-account-show-card-personalizations': boolean
  'cash-web-account-show-if-block-employee': boolean
  'cash-web-account-show-money-tab-uk': FeatureFlagDefaultStringVariant
  'cash-web-account-show-p2p-for-uk': FeatureFlagDefaultStringVariant
  'cash-web-account-show-savings-goal-flow': boolean
  'cash-web-account-show-savings-interest': FeatureFlagDefaultStringVariant
  'cash-web-account-show-savings-round-ups-flow': boolean
  'cash-web-account-show-savings-tab': boolean
  'cash-web-account-show-savings-transfer-balance-flow': boolean
  'cash-web-account-taxes-banner': FeatureFlagDefaultStringVariant
  'cash-web-account-taxes-entrypoint-in-sidebar-nav': FeatureFlagDefaultStringVariant
  'cash-web-account-taxes-returns-entrypoint-in-sidebar-documents-nav': FeatureFlagDefaultStringVariant
  'cash-web-account-verify-alias-additional-help-items': boolean
  'cash-web-add-cash': boolean
  'cash-web-add-cash-settings': { countries?: ReadonlyArray<string> }
  'cash-web-admin-datadog-rum-config': RumConfig
  'cash-web-arcade-migration': ArcadeComponentMigrationConfig
  'cash-web-business-tax-information-react': FeatureFlagDefaultStringVariant
  'cash-web-cashtags-datadog-rum-config': RumConfig
  'cash-web-device-details-endpoint': boolean
  'cash-web-enable-risk-signal': boolean
  'cash-web-enable-tm': boolean
  'cash-web-incidents': Incidents
  'cash-web-lightning-datadog-rum-config': RumConfig
  'cash-web-login-datadog-rum-config': RumConfig
  'cash-web-p2p-allow-search-new-recipients': FeatureFlagDefaultStringVariant
  'cash-web-passkeys': FeatureFlagDefaultStringVariant
  'cash-web-passkeys-try-login-with-passkeys': FeatureFlagDefaultStringVariant
  'cash-web-replace-cash-card-ui': FeatureFlagDefaultStringVariant
  'cash-web-show-money-tab-settings': { countries?: ReadonlyArray<string> }
  'cash-web-standalone-pages-datadog-rum-config': RumConfig
  'cash-web-support-datadog-rum-config': RumConfig
  'cash-web-support-datadog-rum-enabled': boolean
  'cash-web-taxes-account-switcher-version': AccountSwitcherVersion
  'cash-web-taxes-article-list': Record<TaxesArticleName, boolean>
  'cash-web-taxes-datadog-rum-config': RumConfig
  'cash-web-taxes-desktop-applet': FeatureFlagDefaultStringVariant
  'cash-web-taxes-experience-service': boolean
  'cash-web-taxes-open-for-data-entry': FeatureFlagDefaultStringVariant
  'cash-web-taxes-sam-restriction': boolean
  'cash-web-taxes-timeline-event-dates': Record<TimelineStage, string>
  'cash-web-taxes-timeline-stage': TimelineStage
  'cash-web-unsupported-browser-page': FeatureFlagDefaultStringVariant
  'cash-web-use-v2-cdp-endpoint': boolean
  'cashclient-money-movement-copy-changes': FeatureFlagDefaultStringVariant
}
