import styled from '@emotion/styled'
import type { ReactElement } from 'react'

import { Icon } from '@cash-design-system/react'

import CardItem from '../CardItem'
import { LinkAndAnchorStyles } from '../styles'

const StyledAnchor = styled.a`
  ${LinkAndAnchorStyles}
`

export type ICardItemExternalAnchor = {
  title: string
  subtitle?: string
  icon?: ReactElement
  to: string
  newTab?: boolean
  onClickTrackEvent?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  'data-testid'?: string
}

export default function CardItemExternalAnchor({
  to,
  newTab,
  title,
  subtitle,
  icon,
  onClickTrackEvent,
  'data-testid': dataTestId,
}: ICardItemExternalAnchor) {
  const cardProps = { title, subtitle }
  return (
    <StyledAnchor
      href={to}
      data-testid={dataTestId}
      onClick={onClickTrackEvent}
      target={newTab ? '_blank' : '_self'}
      referrerPolicy="same-origin"
    >
      <CardItem {...cardProps} isClickable icon={icon ?? <Icon data-testid="link-out-icon" name="link-out" />} />
    </StyledAnchor>
  )
}
