/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetSponsorshipDataCheckSampleRequest} from '@cash-web/protos/squareup/cash/aegis/service.pb'
import {GetSponsorshipDataCheckSampleResponse} from '@cash-web/protos/squareup/cash/aegis/service.pb'


export const getSponsorshipDataCheckSample = (request:GetSponsorshipDataCheckSampleRequest, options?: CashRequestOptions) => post<GetSponsorshipDataCheckSampleRequest, GetSponsorshipDataCheckSampleResponse>("/2.0/cash/get-sponsorship-data-check-sample", request, {...options, rejectOnError: true })