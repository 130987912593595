import { useCallback, useEffect } from 'react'

import styled from '@emotion/styled'
import type { TFunction } from 'i18next'

import { border } from '@arcade/web'
import { CommPhoneFillIcon, CommSmsFillIcon } from '@arcade/web/icons/react'
import { Header, spacing } from '@cash-design-system/react'
import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import { useRegion } from '@cash-web/shared-feature-region-provider'
import { eventActionType, getElementEventData, getRegionPhoneNumber } from '@cash-web/support/utils'
import type { CustomerSupportAccessViewNodesTrigger, CustomerSupportChannel } from '@squareup/customer-data-events-web'
import {
  CustomerSupportAccessViewContactOptions,
  CustomerSupportContactStart,
  WebInteractClick,
} from '@squareup/customer-data-events-web'

import CardItemExternalAnchor from '../CardItemExternalAnchor'
import type { QrThemes } from '../QRContent'
import QRModal, { qrHashes } from '../QRModal'

const Title = styled(Header)`
  margin-bottom: ${spacing.m};
`

type ResponsiveProps = {
  hideMobile?: boolean
  hideDesktop?: boolean
}

type TriggerProp = {
  trigger?: CustomerSupportAccessViewNodesTrigger
  triggerNodeToken?: string
  viewToken?: string
}

export type ContactUsProps = {
  mobileChatDeepLink: string
  desktopQRPath: string
  desktopQRSize?: number
  desktopQRTheme?: QrThemes
  open: boolean
  onCloseQrModal: () => void
  cdfTrigger: TriggerProp
  t: TFunction
}

const ContactList = styled.ul`
  border-radius: ${border.radius.medium};
  display: block;
  overflow: hidden;

  ${props => props.theme.breakpoints.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacing.s};
    border-radius: inherit;
  }
`

const ContactItem = styled.li`
  position: relative;
  display: ${(props: ResponsiveProps) => (props.hideMobile ? 'none' : 'inherit')};
  border-bottom: ${props => `1px solid ${props.theme.colors.hairline}`};

  &:last-child {
    border-bottom: none;
  }

  ${props => props.theme.breakpoints.tablet} {
    border-bottom: none;
    border-radius: ${border.radius.medium};
    overflow: hidden;
  }

  ${props => props.theme.breakpoints.desktop} {
    display: ${(props: ResponsiveProps) => (props.hideDesktop ? 'none' : 'inherit')};
  }
`

const ContactUs = ({
  mobileChatDeepLink,
  desktopQRPath,
  desktopQRSize,
  desktopQRTheme,
  open,
  onCloseQrModal,
  cdfTrigger,
  t,
}: ContactUsProps) => {
  const region = useRegion()
  const phoneNumber = region ? getRegionPhoneNumber(region) : undefined
  const logCdfEvent = useLogCashWebCdfEvent()
  const trackClickEvent = useCallback(
    (e: { currentTarget: EventTarget & HTMLAnchorElement }, channel?: CustomerSupportChannel) => {
      const hasQrHash = Object.values(qrHashes).includes(e.currentTarget.hash)

      if (cdfTrigger) {
        logCdfEvent(
          CustomerSupportContactStart({
            channel,
            trigger: cdfTrigger.trigger,
            triggerNodeToken: cdfTrigger.triggerNodeToken,
            viewToken: cdfTrigger.viewToken,
          })
        )
      }
      logCdfEvent(
        WebInteractClick(
          getElementEventData(e.currentTarget, hasQrHash ? eventActionType.OPEN_MODAL : eventActionType.LINK_CLICK)
        )
      )
    },
    [logCdfEvent, cdfTrigger]
  )

  const logPageAnalytics = useCallback(() => {
    if (cdfTrigger) {
      logCdfEvent(
        CustomerSupportAccessViewContactOptions({
          nodeToken: cdfTrigger.triggerNodeToken,
          trigger: cdfTrigger.trigger,
          viewToken: cdfTrigger.viewToken,
          chatOption: 'SHOWN',
          phoneOption: phoneNumber ? 'SHOWN' : 'NOT_SHOWN',
        })
      )
    }
  }, [cdfTrigger, logCdfEvent, phoneNumber])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      logPageAnalytics()
    }
    // disable line because we want to ensure this only get called once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section>
      {/* Chat modal */}
      <QRModal
        open={open}
        onClose={onCloseQrModal}
        title={t('Chat with us', { context: 'title for qr with link to chat with us ' })}
        subtitle={t('Scan this code using your phone’s camera to get started.', {
          context: 'instructions to scan a QR code to start a chat',
        })}
        qrPath={desktopQRPath}
        size={desktopQRSize}
        theme={desktopQRTheme}
      />
      <Title variant="tabTitle" color="secondaryLabel" as="h2">
        {t('Contact us', { context: 'contact us title' })}
      </Title>
      <ContactList>
        <ContactItem hideDesktop>
          <CardItemExternalAnchor
            to={mobileChatDeepLink}
            title={t('Start a Chat', { context: 'button title' })}
            subtitle={t('Open your mobile app to chat', {
              context: 'subtitle for button to start a support chat via mobile app',
            })}
            icon={<CommSmsFillIcon data-testid="chat-icon" />}
            onClickTrackEvent={event => trackClickEvent(event, 'CHAT')}
          />
        </ContactItem>
        <ContactItem hideMobile>
          <CardItemExternalAnchor
            to={qrHashes['chat']}
            data-testid="contactus-button-desktop"
            title={t('Start a Chat', { context: 'button title' })}
            subtitle={t('Open your mobile app to chat', {
              context: 'subtitle for button to start a support chat via mobile app',
            })}
            icon={<CommSmsFillIcon data-testid="chat-icon" />}
            onClickTrackEvent={event => trackClickEvent(event, 'CHAT')}
          />
        </ContactItem>
        {phoneNumber && (
          <ContactItem>
            <CardItemExternalAnchor
              to={`tel:${phoneNumber}`}
              title={t('Call us at {{phoneNumber}}', {
                phoneNumber: phoneNumber,
                context: 'button title to initiate a phone call',
              })}
              subtitle={t('Available daily, 9 AM-7 PM ET', {
                context: 'support phone hours availability button subtitle',
              })}
              icon={<CommPhoneFillIcon data-testid="phone-icon" />}
              onClickTrackEvent={event => trackClickEvent(event, 'CALLBACK')}
            />
          </ContactItem>
        )}
      </ContactList>
    </section>
  )
}

export default ContactUs
