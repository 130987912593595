import { StrictMode } from 'react'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'

import { FaviconTags } from '@cash-web/favicon'
import { Country } from '@cash-web/protos/squareup/common/countries.pb'
import { FeatureFlagProvider } from '@cash-web/shared-data-access-feature-flags'
import type { Region } from '@cash-web/shared-feature-region-provider'
import { RegionProvider } from '@cash-web/shared-feature-region-provider'
import { AnalyticsProvider } from '@cash-web/support/utils'

import Layout from '../Layout'
import i18nextConfig from '../next-i18next.config'
import { start } from '../src/lib/bugsnag'

const queryClient = new QueryClient()

start()

const HelpCenterApp = (props: AppProps) => {
  const { Component, pageProps } = props
  const router = useRouter()

  const lookupRegion = router.query['region']?.toString().toUpperCase()

  const region =
    lookupRegion && Object.keys(Country).includes(lookupRegion) ? Country[lookupRegion as Country] : Country.US

  return (
    <>
      <FaviconTags />

      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <RegionProvider region={region as Region}>
            <AnalyticsProvider>
              <FeatureFlagProvider>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </FeatureFlagProvider>
            </AnalyticsProvider>
          </RegionProvider>
        </QueryClientProvider>
      </StrictMode>
    </>
  )
}

export default appWithTranslation(HelpCenterApp, i18nextConfig)
