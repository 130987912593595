import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, Header, spacing } from '@cash-design-system/react'
// eslint-disable-next-line no-restricted-imports
import { BreakpointMin } from '@cash-design-system/tokens'

import type { Link } from '../../testing/testComponents'
import type { LinkAdapterProps } from '../../types/types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing.xxxxl};
  padding-bottom: ${spacing.xxxxl};
  text-align: center;
`

const ButtonContainer = styled.div`
  margin-top: ${spacing.l};
  margin-bottom: ${spacing.l};
  width: 100%;
  max-width: ${BreakpointMin};
`

const linkStyles = css`
  text-decoration: none;
`
const UnstyledAnchor = styled.a(linkStyles)

type Link<T> =
  | (LinkAdapterProps<T> & { anchorProps?: never })
  | {
      Link?: never
      linkProps?: never
      anchorProps: React.AnchorHTMLAttributes<HTMLAnchorElement>
    }

type Action<T> = { title: string } & Link<T>

type Props<T> = {
  title: string
  subtitle?: string
  action?: Action<T>
}

function ActionLink<T>({ action }: { action: Action<T> }) {
  /* TODO: buttons shouldn't be nested inside anchors. 
      Need to export button as link styles in CDS */

  if (action.Link) {
    const UnstyledLink = styled(action.Link)`
      ${linkStyles}
    `

    return (
      <UnstyledLink data-testid="internal-link" styles={linkStyles} {...action.linkProps}>
        <Button>{action.title}</Button>
      </UnstyledLink>
    )
  }

  return (
    <UnstyledAnchor data-testid="external-link" {...action.anchorProps}>
      <Button>{action.title}</Button>
    </UnstyledAnchor>
  )
}

export default function ErrorView<T>({ title, subtitle, action }: Props<T>) {
  return (
    <Container>
      <Header variant="largeLabel" as="h1">
        {title}
      </Header>
      {subtitle && (
        <Header variant="body" as="h2">
          {subtitle}
        </Header>
      )}

      <ButtonContainer>{action && <ActionLink action={action} />}</ButtonContainer>
    </Container>
  )
}
